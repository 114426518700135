import { cloneDeep, get, filter } from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';
import auth from '../utils/auth';
import {setLocaleHeader} from '../utils/services';

const authLocale = auth.getLocale();
if (authLocale) {
  initialState.app.defaultLocale = authLocale;
  initialState.app.isLTR = (get(filter(initialState.app.locale, {value: initialState.app.defaultLocale}), '[0]') || {}).direction === 'ltr';
}

export default function appReducer(state = initialState.app, action) {
  let newState = cloneDeep(state);

  switch (action.type) {
    case types.CHANGE_LOCALE: {
      const locale = action.locale.value;
      newState.defaultLocale = locale;
      newState.isLTR = (get(filter(newState.locale, {value: newState.defaultLocale}), '[0]') || {}).direction === 'ltr';

      auth.setLocale(locale);
      setLocaleHeader(locale);
      window.location.reload(true);
      return newState;
    }

    case types.RESET_HELP: {
      newState.helps.errMsg = '';
      newState.helps.help = {};
      return newState;
    }

    case types.FETCH_HELP_SUCCESS: {
      newState.helps.help = action.data || {};
      return newState;
    }

    case types.FETCH_HELP_FAILURE: {
      if (action.data && action.data.error) {
        newState.helps.errMsg = action.data.error;
      } else {
        newState.helps.errMsg = 'generic.server_error_msg';
      }
      return newState;
    }

    case types.FETCH_NATIONALITIES: {
      newState.nationalities = cloneDeep(initialState.app.nationalities);
      return newState;
    }
    case types.FETCH_NATIONALITIES_SUCCESS: {
      newState.nationalities.data = action.data;
      newState.nationalities.error = null;

      return newState;
    }
    case types.FETCH_NATIONALITIES_FAILURE: {
      newState.nationalities.data = [];
      newState.nationalities.error = action.data.error;

      return newState;
    }

    // case types.FETCH_COUNTRIES:
    // case types.FETCH_COUNTRIES_FAILURE: {
    //   newState.countries = cloneDeep(initialState.app.countries);
    //   return newState;
    // }
    // case types.FETCH_COUNTRIES_SUCCESS: {
    //   newState.countries.data = action.data;
    //   newState.countries.error = null;
    //   newState.countries.isFetched = true;
    //   return newState;
    // }

    default:
      state.defaultLocale = auth.getLocale();
      return state;
  }
}
