'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import withTheme from "@material-ui/core/styles/withTheme";

class Space extends React.Component {

  render() {
    const {start, end, top, bottom, vertical, verticalSpace, horizontal, horizontalSpace, theme} = this.props;

    return (
      <div style={{
        marginTop: top,
        marginBottom: bottom,
        marginLeft: start,
        marginRight: end,
        width: verticalSpace ? theme.spacing(verticalSpace) : vertical,
        height: horizontalSpace ? theme.spacing(horizontalSpace) : horizontal,
      }} />
    );
  }
}

Space.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  start: PropTypes.number,
  end: PropTypes.number,
  vertical: PropTypes.number,
  horizontal: PropTypes.number,
  verticalSpace: PropTypes.number,
  horizontalSpace: PropTypes.number,
};

export default React.memo((withTheme(Space)));
