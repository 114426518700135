import {Scene} from 'phaser';
import {GCCEventBus} from "src/gameCannonCraze/GameCannonCraze";
import {
  addRepeatTimerEvent,
  addTimerEvent,
  checkOverlap,
  formatSeconds,
  getCenterPoint,
  getOverlap
} from "src/game/GameHelper";
import {logDebug} from "src/js/utils/AppLog";

const VELOCITY_CANNON = 80;
const VELOCITY_TARGET_1 = 600;
const VELOCITY_TARGET_2 = 500;
const VELOCITY_TARGET_3 = 400;
const VELOCITY_BALL = 1600;
const TARGET_2_BONUS = 1;
const TARGET_3_BONUS = 4;

export class GCCGame extends Scene {

  constructor() {
    super('GCCGame');
  }

  onEventRestart = () => {
    GCCEventBus.off('mute', this.onEventMute);
    GCCEventBus.off('restart', this.onEventRestart);
    this.scene.stop('GCCGame');
    this.scene.start('GCCGame');
  };

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
    if (this.audioFire) {
      this.audioFire.setMute(mute);
    }
    if (this.audioHit) {
      this.audioHit.setMute(mute);
    }
    if (this.audioHit2) {
      this.audioHit2.setMute(mute);
    }
    if (this.audioHit3) {
      this.audioHit3.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
    if (this.audioGameWin) {
      this.audioGameWin.setMute(mute);
    }
  };

  create() {
    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';
    const stringHint = this.game.options.locale && this.game.options.locale == 'ar' ? 'انقر لتبدأ' : 'Click to Start';
    this.stringHintShoot = this.game.options.locale && this.game.options.locale == 'ar' ? 'انقر لاطلاق النار' : 'Click to Shoot';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.gameEnded = false;
    this.score = 0;
    this.scoreToWin = this.game.options.scoreToWin || 50;
    this.gameTime = this.game.options.gameTime || 60000;
    this.startTime = null;
    this.ballsData = {
      balls: [],
      ballColliders: [],
      activeBallIndexes: new Set(),
    }

    if (this.audioGameBg) {
      this.audioGameBg.stop();
      this.audioGameBg = null;
      GCCEventBus.off('mute', this.onEventMute);
    }
    this.audioGameBg = this.sound.add('audio_bg', {loop: true})
      .setMute(this.game.options.mute);
    this.audioGameBg.play();
    this.audioFire = this.sound.add('audio_fire').setMute(this.game.options.mute)
      .setVolume(0.5);
    this.audioHit = this.sound.add('audio_hit').setMute(this.game.options.mute)
      .setVolume(0.5);
    this.audioHit2 = this.sound.add('audio_hit2').setMute(this.game.options.mute)
      .setVolume(0.5);
    this.audioHit3 = this.sound.add('audio_hit3').setMute(this.game.options.mute)
      .setVolume(0.5);
    this.audioGameEnd = this.sound.add('audio_game_end').setMute(this.game.options.mute)
      .setVolume(0.2);
    this.audioGameWin = this.sound.add('audio_game_win').setMute(this.game.options.mute)
      .setVolume(0.5);
    GCCEventBus.on('mute', this.onEventMute);
    GCCEventBus.on('restart', this.onEventRestart);

    this.bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    this.bgImage.setDisplaySize(gameWidth, gameHeight);

    this.progressTimer = this.add.rectangle(0, 0, 0, 24, 0xFFFFFF)
      .setOrigin(0, 0)
      .setAlpha(0.5);

    this.textScore = this.add.text(gameWidth / 2, 85, this.score, {
      fontFamily: 'Poppins',
      fontSize: 100, color: '#333',
      stroke: '#999', strokeThickness: 2,
      align: 'center',
    }).setOrigin(0.5)
      .setDepth(2)
      .setShadow(0, 0, 0xffffff, 4);

    this.add.image(gameWidth - 246, 78, 'timer')
      .setDisplaySize(54, 54);
    this.textTime = this.add.text(gameWidth - 140, 80, formatSeconds(this.gameTime/1000), {
      fontFamily: 'Poppins',
      fontSize: 50, color: '#555',
      stroke: '#999', strokeThickness: 1,
      align: 'center',
    }).setOrigin(0.5)
      .setDepth(2)
      .setShadow(0, 0, 0xffffff, 4);

    this.cannon = this.physics.add.image(gameWidth/2, gameHeight-190, 'cannon').setDepth(1);

    this.target3 = this.physics.add.image(gameWidth/2, 320, 'target3');
    this.target3.name = 'target3';
    const target3BodyHeight = this.target3.body.height;
    this.target3.setBodySize(this.target3.body.width * .88, 20);
    this.target3.setOffset(this.target3.body.width * .06, target3BodyHeight - 50);

    this.target2 = this.physics.add.image(gameWidth/2, this.target3.y + this.target3.height/2 + 150, 'target2');
    this.target2.name = 'target2';
    const target2BodyHeight = this.target2.body.height;
    this.target2.setBodySize(this.target2.body.width * .88, 20);
    this.target2.setOffset(this.target2.body.width * .06, target2BodyHeight - 40);

    this.target1 = this.physics.add.image(gameWidth/2, this.target2.y + this.target2.height/2 + 160, 'target1');
    this.target1.name = 'target1';
    const target1BodyHeight = this.target1.body.height;
    this.target1.setBodySize(this.target1.body.width * .88, 20);
    this.target1.setOffset(this.target1.body.width * .06, target1BodyHeight - 40);

    this.textHint = this.add.text(gameWidth / 2, gameHeight - 600, stringHint, {
      fontFamily: 'Poppins',
      fontSize: 80, color: '#ffffff',
      stroke: '#48464F', strokeThickness: 2,
      align: 'center'
    }).setOrigin(0.5)
      .setAlpha(0.9);

    this.gameOverText = this.add.text(gameWidth / 2, 320, this.stringGameOver, {
      fontFamily: 'Poppins',
      fontSize: 120, color: '#666',
      backgroundColor: '#eee',
      stroke: '#000', strokeThickness: 4,
      align: 'center', padding: 24,
    }).setOrigin(0.5).setVisible(false).setDepth(2);

    this.readyToPlayGame();
  }

  update() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    if (this.startTime) {
      const timeElapsed = (this.gameTime - (Date.now() - this.startTime))/1000;
      this.textTime.setText(formatSeconds(timeElapsed));
      this.progressTimer.width = Math.floor(gameWidth - gameWidth/(this.gameTime/1000) * timeElapsed);

      if (this.cannon.angle <= -50 || this.cannon.angle >= 50) {
        if (this.cannon.angle <= -50) {
          this.cannon.setAngularVelocity(Math.abs(this.cannon.angularVelocity));
        } else {
          this.cannon.setAngularVelocity(-Math.abs(this.cannon.angularVelocity));
        }
      }
      if (this.target1.x <= this.target1.displayWidth/2 + 40 || this.target1.x >= gameWidth - this.target1.displayWidth/2 - 40) {
        if (this.target1.x <= this.target1.displayWidth/2 + 40) {
          this.target1.setVelocityX(Math.abs(this.target1.velocityX));
        } else {
          this.target1.setVelocityX(-Math.abs(this.target1.velocityX));
        }
      }
      if (this.target2.x <= this.target2.displayWidth/2 + 40 || this.target2.x >= gameWidth - this.target2.displayWidth/2 - 40) {
        if (this.target2.x <= this.target2.displayWidth/2 + 40) {
          this.target2.setVelocityX(Math.abs(this.target2.velocityX));
        } else {
          this.target2.setVelocityX(-Math.abs(this.target2.velocityX));
        }
      }
      if (this.target3.x <= this.target3.displayWidth/2 + 40 || this.target3.x >= gameWidth - this.target3.displayWidth/2 - 40) {
        if (this.target3.x <= this.target3.displayWidth/2 + 40) {
          this.target3.setVelocityX(Math.abs(this.target3.velocityX));
        } else {
          this.target3.setVelocityX(-Math.abs(this.target3.velocityX));
        }
      }
    }
  }

  getArcPoint = () => {
    const centerX = this.cannon.x;
    const centerY = this.cannon.y;
    const radius = this.cannon.height/2 - 25;
    const angle = 270 + this.cannon.angle;
    // Convert angle from degrees to radians (if needed)
    const radians = angle * Math.PI / 180;

    // Calculate coordinates using trigonometric functions
    const x = centerX + radius * Math.cos(radians);
    const y = centerY + radius * Math.sin(radians);

    // Return the point object with calculated coordinates
    return new Phaser.Geom.Point(x, y);
  }

  getVelocityX = (x1, x2) => {
    const deltaTime = 10;
    // Calculate the change in x position
    const deltaX = x2 - x1;

    // Calculate velocity in x direction (considering time elapsed)
    const velocityX = deltaX / deltaTime;

    // Return the x velocity
    return velocityX;
  }

  processBall = () => {
    // reset out indexes
    if (this.ballsData.activeBallIndexes.size > 0) {
      const gameWidth = this.game.config.width;
      const gameHeight = this.game.config.height;

      let indexesToReset = null;
      this.ballsData.activeBallIndexes.forEach((ballIndex) => {
        const ball = this.ballsData.balls[ballIndex];
        if (ball.y < -ball.displayHeight || ball.x < -ball.displayWidth || ball.x > gameWidth + ball.displayWidth) {
          if (!indexesToReset) {
            indexesToReset = [];
          }
          indexesToReset.push(ballIndex);
          ball.setPosition(gameWidth/2, gameHeight - 50);
          ball.setVisible(false);
        }
      });
      if (indexesToReset && indexesToReset.length > 0) {
        indexesToReset.forEach((indexToReset) => {
          this.ballsData.activeBallIndexes.delete(indexToReset);
        });
      }
    }

    let ballIndex = -1;
    for (let i = 0; i < this.ballsData.balls.length; i++) {
      if (!this.ballsData.activeBallIndexes.has(i)) {
        ballIndex = i;
        break;
      }
    }
    if (ballIndex === -1) {
      const ballSize = 50;
      const ball = this.physics.add.image(0, -ballSize, 'ball');
      ball.setDisplaySize(ballSize, ballSize);
      ball.setOffset(ball.body.width * .05, ball.body.height * .65);
      ball.setBodySize(ball.body.width * .9, ball.body.height * .3, false);
      this.ballsData.ballColliders.push(this.physics.add.overlap(ball, this.target1, this.onTargetHit));
      this.ballsData.ballColliders.push(this.physics.add.overlap(ball, this.target2, this.onTargetHit));
      this.ballsData.ballColliders.push(this.physics.add.overlap(ball, this.target3, this.onTargetHit));

      this.ballsData.balls.push(ball);
      ballIndex = this.ballsData.balls.length - 1;
    }
    if (ballIndex >= 0) {
      this.ballsData.activeBallIndexes.add(ballIndex);
      const ball = this.ballsData.balls[ballIndex];
      const position = this.getArcPoint();
      ball.setPosition(position.x, position.y);
      ball.setVelocity(this.cannon.angle * 15, -VELOCITY_BALL);
      ball.setVisible(true);
    }
  }

  attachPointers = () => {
    this.input.on('pointerdown', this.onClick);
  }

  detachPointers = () => {
    this.input.off('pointerdown', this.onClick);
  }

  onClick = () => {
    if (this.startTime) {
      this.audioFire.play();
      this.processBall();
      this.cannon.setAlpha(0.6);
      this.detachPointers();
      addTimerEvent(this, 300, () => {
        this.cannon.setAlpha(1);
        if (!this.gameEnded) {
          this.attachPointers();
        }
      });
    } else {
      this.startTime = Date.now();

      const velocityFactors = [-1, 1];

      const cannonAngularVelocity = velocityFactors[Phaser.Math.Between(0, 1)] * VELOCITY_CANNON;
      this.cannon.setAngularVelocity(cannonAngularVelocity);
      this.cannon.angularVelocity = cannonAngularVelocity;

      const target1VelocityX = velocityFactors[Phaser.Math.Between(0, 1)] * VELOCITY_TARGET_1;
      this.target1.setVelocityX(target1VelocityX);
      this.target1.velocityX = target1VelocityX;

      const target2VelocityX = velocityFactors[Phaser.Math.Between(0, 1)] * VELOCITY_TARGET_2;
      this.target2.setVelocityX(target2VelocityX);
      this.target2.velocityX = target2VelocityX;

      const target3VelocityX = velocityFactors[Phaser.Math.Between(0, 1)] * VELOCITY_TARGET_3;
      this.target3.setVelocityX(target3VelocityX);
      this.target3.velocityX = target3VelocityX;

      this.textHint.setText(this.stringHintShoot);
      addTimerEvent(this, 1000, () => {
        this.textHint.setVisible(false);
        GCCEventBus.emit('eventOnStart');
      });

      addTimerEvent(this, this.gameTime, () => {
        if (!this.gameEnded) {
          this.endGame();
        }
      });
    }
  };

  onTargetHit = (ball, otherObject) => {
    // const overlap = getOverlap(car, otherObject);
    // if (overlap && overlap.length > 1) {
    //   // const overlap[0]
    //   // Extract intersection data from the result object
    //   logDebug('overlap', overlap);
    //   const centerPoint = getCenterPoint(overlap[0], overlap[1]);
    //
    //   const size = 300;
    //   this.add.image(centerPoint.x, centerPoint.y, 'crash')
    //     .setDisplaySize(size, size)
    //     .setDepth(1);
    // }
    this.score += 1;
    if (otherObject.name === 'target3') {
      this.score += TARGET_3_BONUS;
      this.audioHit3.play();
    } else if (otherObject.name === 'target2') {
      this.score += TARGET_2_BONUS;
      this.audioHit2.play();
    } else {
      this.audioHit.play();
    }
    ball.setVelocity(0, 0);
    ball.setPosition(this.game.config.width/2, this.cannon.y)
    ball.setVisible(false);
    otherObject.setAlpha(0.6);
    addTimerEvent(this, 200, () => {
      otherObject.setAlpha(1);
    });
    this.textScore.setText(this.score);
    if (this.score >= this.scoreToWin) {
      this.endGame();
    }
  }

  readyToPlayGame = () => {
    if (!this.gameEnded) {
      this.attachPointers();
    }
    this.ballsData.ballColliders.forEach((ballCollider) => {
      ballCollider.active = true;
    });
  }

  endGame = () =>  {
    this.gameEnded = true;
    this.startTime = null;
    this.detachPointers();

    this.cannon.setAngularVelocity(0);
    this.cannon.setAngle(0);
    this.target1.setVelocityX(0);
    this.target2.setVelocityX(0);
    this.target3.setVelocityX(0);

    this.ballsData.balls.forEach((ball) => {
      ball.setVelocity(0, 0);
    });
    this.ballsData.ballColliders.forEach((ballCollider) => {
      ballCollider.active = false;
    });


    const isWin = this.score >= this.scoreToWin;
    if (isWin) {
      this.audioGameWin.play();
      this.gameOverText.setText(this.stringWellDone);
    } else {
      this.audioGameEnd.play();
      this.gameOverText.setText(this.stringGameOver);
    }

    addTimerEvent(this, 1000, () => {
      this.gameOverText.setVisible(true);
      addTimerEvent(this, 1000, () => {
        GCCEventBus.emit('eventOnEnd', this.score);
      });
    });
  }
}
