import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import '../styles/styles.scss';
import * as serviceWorker from './utils/serviceWorker';
import configureStore, {history} from './store/configureStore';
import Root from './components/Root';

require('../images/favicon.png');

window.google.charts.load('current', {'packages': ['map']});
const store= configureStore();

render(
  <Provider store={store}>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
    <link href="https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap" rel="stylesheet" />
    <link href="https://vjs.zencdn.net/8.5.2/video-js.css" rel="stylesheet" />
    <link rel="apple-touch-icon" href="/icons/icon-144x144.png" />
    <ConnectedRouter history={history}>
      <Root/>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const {AppContainer} = require('react-hot-loader')
    const NewRoot = require('./components/Root').default;
    window.HELP_IMPROVE_VIDEOJS = false;

    render(
      <Provider store={store}>
        <AppContainer>
          <ConnectedRouter history={history}>
            <NewRoot store={store} history={history}/>
          </ConnectedRouter>
        </AppContainer>
      </Provider>,
      document.getElementById('app')
    );
  });
}

serviceWorker.register();
