import {history} from "../../store/configureStore";
import constants from "src/js/constants";
import {logDebug} from "src/js/utils/AppLog";

export const PathAboutUsPublic = '/aboutUs';
export const PathAccount = '/account';
export const PathHome = '/';
export const PathHowItWorks = '/howItWorks';
export const PathRedeemOffer = '/redeemOffer';
export const PathHelpRedeemOffer = '/helpRedeemOffer';
export const PathVerifyAccount = '/verifyAccount';
export const PathGameGuidelines = '/gamesGuidelines';
export const PathGames = '/games';
export const PathGamesManage = '/games/manage';
export const PathLogout = '/logout';
export const PathLogin = '/login';
export const PathRegister = '/register';
export const PathJoin = PathLogin;
export const PathResetPassword = '/resetPassword';
export const PathReward = '/won';
export const PathPayment = '/payment';
export const PathPaymentEnd = '/paymentConfirmation';
export const PathPaymentRedirect = '/paymentRedirects';
export const PathPaymentStart = '/paymentProcessing';
export const PathPlay = '/play';
export const PathActivityReviews = '/campaigns/reviews';
export const PathProfile = '/profile';
export const PathSettings = '/settings';
export const PathMyReels = '/profile/reels';
export const PathPlays = '/plays';
export const PathCampaigns = '/';
export const PathCampaignManage = '/campaigns/manage';
export const PathCampaignsLetter = '/campaigns';

export const navigateToPlay = (item, replace = false) => {
  const path = {
    pathname: getPathForActivity(item) +'/'+ item.id,
    state: {canGoBack: true}
  };
  if (replace) {
    history.replace(path);
  } else {
    history.push(path);
  }
}

export const getPathForActivity = (item) => {
  if (item.type === constants.activity.types.OFFER
    || item.type === constants.activity.types.VOUCHER
    || item.type === constants.activity.types.DISCOUNT) {
    return PathReward;
  }
  return PathPlay;
}

export const navBackOrHome = (history) => {
  logDebug('navBackOrHome:historyLength', history.length)
  if (history.length <= 1) {
    // window.close();
    history.replace('/');
  } if (history.length <= 2) {
    history.replace('/');
  } else {
    history.goBack();
  }
}

export const navBackOrExit = (history) => {
  logDebug('navBackOrHome:historyLength', history.length)
  if (history.length <= 1) {
    window.close();
    // history.replace('/');
  } if (history.length <= 2) {
    history.replace('/');
  } else {
    history.goBack();
  }
}

export const getURLParameters = (search) => {
  const params = new URLSearchParams(search);
  const parameters = {};

  for (const [key, value] of params.entries()) {
    parameters[key] = value;
  }

  return parameters;
}

export const getRedirectSearch = (location) => {
  return '?r='+ location.pathname + (location.search && location.search.length > 0 ? location.search : '') + (location.hash && location.hash.length > 0 ? location.hash : '');
}
