import * as firebase from 'firebase/app';
import 'firebase/messaging';
import constants from '../constants';
import browserStorage from '../utils/browserStorage';

export const NOTIFICATION_TOKEN_KEY = 'rab_v1_token_notification';

const config = {
  messagingSenderId: constants.firebase.senderId
};

const initializedFirebaseApp = firebase.initializeApp(config);
const isSupported = firebase.messaging.isSupported();
let messaging;
if (isSupported) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    constants.firebase.publicVapidKey
  );
}

export {isSupported}

export const askForPermissionToReceiveNotifications = () => {
  return messaging.requestPermission()
    .then(() => {
      return messaging.getToken();
    });
};

export const saveToken = (token) => {
  browserStorage.setItem(NOTIFICATION_TOKEN_KEY, token);
};

export const getToken = () => {
  browserStorage.getItem(NOTIFICATION_TOKEN_KEY);
};

export const deleteToken = () => {
  browserStorage.removeItem(NOTIFICATION_TOKEN_KEY);
};
