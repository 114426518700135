/* eslint-disable no-fallthrough */
import {cloneDeep, has, isArray, toNumber} from 'lodash';

import initialState from './initialState';
import validator from '../validator';
import * as types from '../constants/actionTypes';
import auth from '../utils/auth';
import constants from '../constants';
import {history} from "../store/configureStore";
import {logDebug} from "../utils/AppLog";
import {REFRESH_USER_FROM_STORE} from "../constants/actionTypes";

export default function profileReducer(state = cloneDeep(initialState.admin), action) {
  switch (action.type) {
    case types.RESET_LOGIN_ERROR_MESSAGE:
    case types.RESET_PROFILE_ERROR_MESSAGE: {
      state.profile.errMsg = '';
      state.profile.errMsgChangePassword = '';
      return state;
    }

    case types.SHOW_CHANGE_PASSWORD_MODAL: {
      state.profile.successMsg = '';
      state.profile.showChangePasswordModal = true;
      return state;
    }

    case types.HIDE_CHANGE_PASSWORD_MODAL: {
      state.profile.showChangePasswordModal = false;
      state.profile.errMsgChangePassword = '';
      return state;
    }

    case types.RESET_CHANGE_PASSWORD_ERROR_MESSAGE: {
      state.profile.errMsgChangePassword = '';
      return state;
    }
    case types.DO_CHANGE_PASSWORD: {
      state.profile.errMsgChangePassword = '';
      return state;
    }
    case types.DO_CHANGE_PASSWORD_SUCCESS: {
      state.profile.successMsg = 'login.forgot_password.change_password_success';
      return state;
    }
    case types.DO_CHANGE_PASSWORD_FAILURE: {
      if (action.data && action.data.error) {
        state.profile.errMsgChangePassword = action.data.error;
      } else {
        state.profile.errMsgChangePassword = 'generic.server_error_msg';
      }
      return state;
    }

    case types.SHOW_CHANGE_SETTINGS_MODAL: {
      state.profile.successMsg = '';
      state.profile.showChangeSettingsModal = true;
      return state;
    }

    case types.HIDE_CHANGE_SETTINGS_MODAL: {
      state.profile.showChangeSettingsModal = false;
      state.profile.errMsgSettings = '';
      return state;
    }

    case types.DO_CHANGE_SETTINGS: {
      state.profile.errMsgSettings = '';
      return state;
    }

    case types.DO_CHANGE_SETTINGS_SUCCESS: {
      if (has(action, 'allowBalanceTransfer')) {
        state.profile.data.allowBalanceTransfer = action.allowBalanceTransfer;
      }
      return state;
    }

    case types.DO_CHANGE_SETTINGS_FAILURE: {
      if (action.data && action.data.error) {
        state.profile.errMsgSettings = action.data.error;
      } else {
        state.profile.errMsgSettings = 'generic.server_error_msg';
      }
      return state;
    }

    case types.SHOW_CHANGE_MOBILE_MODAL: {
      state.profile.successMsg = '';
      state.profile.showChangeMobileModal = true;
      return state;
    }

    case types.HIDE_CHANGE_MOBILE_MODAL: {
      state.profile.showChangeMobileModal = false;
      state.profile.errMsgChangeMobile = '';
      return state;
    }

    case types.DO_CHANGE_MOBILE_NUMBER: {
      state.profile.errMsgChangeMobile = '';
      return state;
    }

    case types.DO_CHANGE_MOBILE_NUMBER_SUCCESS: {
      if (has(action, 'mobileNumber')) {
        state.business.mobileNumberWC = action.mobileNumber;
        state.profile.mobileNumberWC = action.mobileNumber;
      }
      state.profile.successMsg = 'admin.profile.change_mobile.success';
      return state;
    }

    case types.DO_CHANGE_MOBILE_NUMBER_FAILURE: {
      if (action.data && action.data.error) {
        state.profile.errMsgChangeMobile = action.data.error;
      } else {
        state.profile.errMsgChangeMobile = 'generic.server_error_msg';
      }
      return state;
    }

    case types.UPDATE_PROFILE_MODE: {
      state.profile.successMsg = '';
      state.profile.isSuccess = false;
      state.profile.isEditing = !state.profile.isEditing;
      return state;
    }

    case types.FETCH_PROFILE: {
      state.profile.successMsg = '';
      state.profile.errMsg = '';
      return state;
    }

    case types.FETCH_PROFILE_SUCCESS: {
      let data = action.data ? cloneDeep(action.data) : {};
      data = formatProfileData(data);
      auth.setTokenUser(auth.getToken(), data);

      state.user = cloneDeep(data);
      state.profile.data = cloneDeep(data);
      state.profile.existing_data = data;
      return state;
    }

    case types.FETCH_PROFILE_FAILURE: {
      if (action.data && action.data.error) {
        state.profile.errMsg = action.data.error;
      } else {
        state.profile.errMsg = 'generic.server_error_msg';
      }
      return state;
    }

    case types.FETCH_HOME: {
      state.home = {};
      return state;
    }

    case types.FETCH_HOME_SUCCESS: {
      state.home = action.data ? cloneDeep(action.data) : {};
      return state;
    }

    case types.FETCH_HOME_FAILURE: {
      if (action.data && action.data.error) {
        state.home.errMsg = action.data.error;
      } else {
        state.home.errMsg = 'generic.server_error_msg';
      }
      return state;
    }

    case types.UPDATE_PROFILE_DATA: {
      if (action.field === 'categoryId') {
        state.profile.data.subCategoryId = null;
        state.profile.subCategories = [];
      }
      if (action.field === 'localityId') {
        state.profile.data.subLocalityId = null;
        state.profile.subLocalities = [];
      }

      state.profile.data[action.field] = sanitizeValue(action.field, action.value);
      delete state.profile.errors[action.field];
      return state;
    }

    case types.CANCEL_PROFILE_UPDATE: {
      state.profile.isEditing = false;
      state.profile.errMsg = '';
      state.profile.data = cloneDeep(state.profile.existing_data);
      return state;
    }

    case types.RESET_PROFILE_DATA: {
      if (isArray(action.fields)) {
        action.fields.forEach(function(field) {
          if (state.profile[field]) {
            state.profile[field] = cloneDeep(initialState.admin.profile)[field];
          }
        });
      } else {
        state.profile = cloneDeep(initialState.admin.profile);
      }
      return state;
    }

    case types.FETCH_CATEGORIES_SUCCESS: {
      let data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      if (action.id) {
        state.profile.data.subCategoryId = null;
        state.profile.subCategories = data;
        if (state.profile.subCategories.length > 0) {
          state.profile.data.subCategoryId = state.profile.subCategories[0].id;
        }
      } else {
        state.profile.categories = data;
      }
      return state;
    }

    case types.FETCH_LOCALITIES_SUCCESS: {
      let data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      if (action.id) {
        state.profile.data.subLocalityId = null;
        state.profile.subLocalities = data;
      } else {
        state.profile.data.localityId = null;
        state.profile.data.subLocalityId = null;
        state.profile.localities = data;
      }
      return state;
    }

    case types.RESET_LOCALITIES_DATA: {
      state.profile.localities = [];
      if (state.profile.errors.subLocalityId && !state.profile.data.localityId) {
        state.profile.errors.subLocalityId = undefined;
      }
      return state;
    }

    case types.RESET_SUB_LOCALITIES_DATA: {
      state.profile.subLocalities = [];
      if (state.profile.errors.subLocalityId && !state.profile.data.localityId) {
        state.profile.errors.subLocalityId = undefined;
      }
      return state;
    }

    case types.VALIDATE_PROFILE_DATA: {
      state.profile.errMsg = '';
      if (action.field, action.value) {
        state.profile.errors[action.field] = validator('profile', action.field, action.value);
      } else {
        const data = cloneDeep(state.profile.data);
        state.profile.errors = getValidationErrors(data);
      }
      return state;
    }

    case types.DO_PROFILE_UPDATE: {
      state.profile.errMsg = '';
      return state;
    }

    case types.DO_PROFILE_UPDATE_SUCCESS: {
      let user = (action.data) || {};
      user = formatProfileData(user);
      auth.setTokenUser(auth.getToken(), user);

      state.user = cloneDeep(action.data);
      state.profile.data = cloneDeep(action.data);
      state.profile.errMsg = '';
      state.profile.errors = {};
      state.profile.isSuccess = true;
      state.profile.isEditing = false;

      if (
        state.user.state === constants.businessStates.APPROVAL_PENDING ||
        state.user.state === constants.businessStates.APPROVAL_REJECTED
      ) {
        state.profile.successMsg = 'admin.profile.passed_for_approval';
      }
      return state;
    }

    case types.DO_PROFILE_UPDATE_FAILURE: {
      if (action.data && action.data.error) {
        state.profile.errMsg = action.data.error;
      } else {
        state.profile.errMsg = 'generic.server_error_msg';
      }
      return state;
    }

    case types.DO_ACCOUNT_UPDATE: {
      state.profile.errMsg = '';
      return state;
    }
    case types.DO_ACCOUNT_UPDATE_SUCCESS: {
      let user = (action.data) || {};
      user = formatProfileData(user);
      auth.setTokenUser(auth.getToken(), cloneDeep(user));

      setTimeout(() => {
        history.replace(action.moveToPath || '/');
      }, 200);

      state.user = cloneDeep(action.data);
      return state;
    }
    case types.DO_ACCOUNT_UPDATE_FAILURE: {
      if (action.data && action.data.error) {
        state.profile.errMsg = action.data.error;
      } else {
        state.profile.errMsg = 'generic.server_error_msg';
      }
      return state;
    }

    case types.RESET_INITIATE_VERIFY_ACCOUNT_ERROR_MESSAGE:
    case types.DO_INITIATE_VERIFY_ACCOUNT: {
      state.profile.errMsgIVA = null;
      state.profile.errMsgIVAF = null;
      return state;
    }
    case types.DO_INITIATE_VERIFY_ACCOUNT_SUCCESS: {
      return state;
    }
    case types.DO_INITIATE_VERIFY_ACCOUNT_FAILURE: {
      if (action.data && action.data.error) {
        state.profile.errMsgIVAF = action.data.error;
      } else {
        state.profile.errMsgIVA = 'generic.server_error_msg';
      }
      return state;
    }

    case types.DO_VERIFY_ACCOUNT: {
      state.profile.errMsgVAF = null;
      state.profile.errMsgVA = null;
      return state;
    }
    case types.DO_VERIFY_ACCOUNT_SUCCESS: {
      if (auth.isAuthenticated()) {
        let user = auth.getUser();
        if (user) {
          user = cloneDeep(user);
          user.isEmailActive = true;
          auth.setUser(user);

          const newState = cloneDeep(initialState.admin);
          newState.user = cloneDeep(user);
          return newState;
        }
      }
      return state;
    }
    case types.DO_VERIFY_ACCOUNT_FAILURE: {
      if (action.data && action.data.error) {
        state.profile.errMsgVAF = action.data.error;
      } else {
        state.profile.errMsgVA = 'generic.server_error_msg';
      }
      return state;
    }
    case types.REFRESH_USER_FROM_STORE: {
      if (auth.isAuthenticated()) {
        let user = auth.getUser();
        if (user) {
          user = cloneDeep(user);
          const newState = cloneDeep(initialState.admin);
          newState.user = cloneDeep(user);
          return newState;
        }
      }
      return state;
    }

    default:
      return state;
  }
}

export function formatProfileData(data) {
  if (data.numberCode && data.mobileNumber) {
    data.mobileNumberWC = '+' + data.numberCode + '-' + data.mobileNumber;
  } else {
    data.mobileNumberWC = '';
  }

  if (data.latitude) {
    try {
      data.latitude = parseFloat(data.latitude);
    // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  if (data.longitude) {
    try {
      data.longitude = parseFloat(data.longitude);
    // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return data;
}

function sanitizeValue(field, value) {
  switch(field) {
    case 'username':
    case 'email':
    case 'mobileNumber':
    case 'password':
    case 'confirmPassword':
    case 'name':
    case 'nameAr':
    case 'recordNumber':
    case 'recordImageFile':
    case 'formImageFile':
    case 'imageFile':
    case 'contactPersonName':
    case 'contactPersonEmail':
    case 'contactPersonNumber':
    case 'bio':
    case 'businessName':
      return value;

    case 'owingType':
    case 'categoryId':
    case 'subCategoryId':
    case 'localityId':
    case 'subLocalityId':
    case 'latitude':
    case 'longitude':
      if (!value) {
        return null;
      } else {
        return toNumber(value);
      }
  }
}

export const getValidationErrors = function (data) {
  let errors = {};

  initialState.admin.profile.requiredFields.forEach((field) => {
    const error = validator('profile', field, data[field]);

    if (error) {
      errors[field] = error;
    } else {
      delete errors[field];
    }
  });

  return errors;
}
