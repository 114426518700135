import {
  ACTIVITY_TYPE_CHALLENGE,
  ACTIVITY_TYPE_CUSTOMER_POST,
  ACTIVITY_TYPE_DISCOUNT,
  ACTIVITY_TYPE_OFFER,
  ACTIVITY_TYPE_OPINION,
  ACTIVITY_TYPE_PREDICTION,
  ACTIVITY_TYPE_QUESTION,
  ACTIVITY_TYPE_SMART_AD,
  ACTIVITY_TYPE_VISITOR_OPINION,
  ACTIVITY_UPDATE_STATE_PREVIEWED,
  AUDIO_POSTER_IMAGE_URL,
  CREATED_TYPE_ADMIN,
  CREATED_TYPE_BUSINESS,
  CREATED_TYPE_EMPLOYEE,
  FEED_TYPE_AD,
  FEED_TYPE_POST,
  POST_TYPE_CHALLENGE,
  STATE_NOTIFICATION_READ,
  STORE_TYPE_EXPRESS,
} from 'src/js/constants/ApiConstants';
// import ApiTask from 'app/src/data/ApiTask';
//import DataStore from 'app/src/data/DataStore';
import {ColorNegative, ColorOrange, ColorTheme} from 'src/styles/Colors';
import utils from 'src/js/utils/utils';
import {API_ASSET_URI, APP_BASE_URI, APP_GAME_DEFAULT_IMAGES_URI, APP_GAME_POOL_IMAGES_URI} from "./services";
import constants from "../constants";
import {gameInfo as gameTapTapShotsInfo} from "src/gameTapTapShots/GameTapTapShots";
import {gameInfo as gameFreeKicksInfo} from "src/gameFreeKicks/GameFreeKicks";
import {gamePacManRushInfo} from "src/gamePacManRush/GamePacManRush";
import {gameTrafficGoInfo} from "src/gameTrafficGo/GameTrafficGo";
import {gameCannonCrazeInfo} from "src/gameCannonCraze/GameCannonCraze";
import {gameSnakeUpInfo} from "src/gameSnakeUp/GameSnakeUp";
import {gameArcHunterInfo} from "src/gameArcHunter/GameArcHunter";

export const isEmailValid = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Basic validation using regex
  if (!regex.test(email)) {
    return false;
  }

  // Additional checks:
  // - Check if domain part contains a dot (.)
  const domainParts = email.split('@')[1].split('.');
  if (domainParts.length < 2) {
    return false;
  }

  // - Check if top-level domain (TLD) is not empty
  const tld = domainParts[domainParts.length - 1];
  if (tld.length === 0) {
    return false;
  }

  // You can add more specific checks here if needed (e.g., allowed characters in local part)
  return true;
}

export default class ApiHelper {

  static isPublicAsset(url) {
    if (url) {
      return url.startsWith(API_ASSET_URI);
    }
    return false;
  }

  static getDateFromBirthday(dateString) {
    if (!dateString) {
      return null;
    }
    let splits = dateString.split('-');
    return new Date(parseInt(splits[0]), parseInt(splits[1]) - 1, parseInt(splits[2]));
  }

  static getUserName(user) {
    if (!user) {
      return null;
    }
    let name = '';
    if (user.name) {
      name += user.name;
    }
    if (user.username) {
      if (user.meType === CREATED_TYPE_EMPLOYEE) {
        name += ' ['+ user.username +']' ;
      } else if (user.meType === CREATED_TYPE_BUSINESS || user.meType === CREATED_TYPE_ADMIN) {
        name += ' @' + user.username;
      }
    }
    return name;
  }

  static saveLocation(location, onSuccess, onFailure) {
    let endpoint = 'ENDPOINT_USER_LOCATIONS';
    let data = {
      latitude: location.latitude,
      longitude: location.longitude
    };
    // ApiTask.getInstance().post(endpoint, data, (response) => {
    //   if (onSuccess) {
    //     onSuccess();
    //   }
    // }, (errorType, error) => {
    //   if (onFailure) {
    //     onFailure(errorType, error);
    //   }
    // });
  }

  static makeApiToGetMe(callback = null) {
    // ApiTask.getInstance().get('ENDPOINT_ME', null, (response) => {
    //   DataStore.getInstance().setUser(response, (authUser) => {
    //     if (callback) {
    //       callback(authUser.user);
    //     }
    //   });
    // }, (errorType, error) => {
    // });
  }

  static getMobileNumber(mobileNumberCode, mobileNumber) {
    return '+'+ mobileNumberCode +'-'+ mobileNumber;
  }

  static isAmountValid(amount) {
    if (amount && amount > 0 && amount < 1000000000) {
      return true;
    }
    return false;
  }

  static getStateActionColor(type) {
    if (type === 1) {
      return ColorTheme;
    }
    if (type === 2) {
      return ColorOrange;
    }
    if (type === 3) {
      return ColorNegative;
    }
    return ColorTheme;
  }

  static markNotificationRead(notification, onSuccess, onFailure) {
    if (notification.id && notification.state !== STATE_NOTIFICATION_READ) {
      let endpoint = 'ENDPOINT_EMPLOYEE_ME_NOTIFICATION_STATES' + '/' + notification.id;
      let data = {
        'state': STATE_NOTIFICATION_READ
      };
      // ApiTask.getInstance().post(endpoint, data, (response) => {
      //   notification.state = data.state;
      //   if (onSuccess) {
      //     onSuccess();
      //   }
      // }, (errorType, error) => {
      //   if (onFailure) {
      //     onFailure(errorType, error);
      //   }
      // });
    }
  }

  static markActivityFetched(activity, onSuccess, onFailure) {
    const endpoint = 'ENDPOINT_ACTIVITY_STATE' + '/' + activity.id;
    const data = {
      state: ACTIVITY_UPDATE_STATE_PREVIEWED,
      dp: activity.dp,
      aid: activity.aid,
    };
    // ApiTask.getInstance().post(endpoint, data, (response) => {
    //   if (onSuccess) {
    //     onSuccess();
    //   }
    // }, (errorType, error) => {
    //   if (onFailure) {
    //     onFailure(errorType, error, activity.id);
    //   }
    // });
  }

  static markActivityPostFetched(activity, activityReview, onSuccess, onFailure) {
    const endpoint = 'ENDPOINT_ACTIVITY_REVIEW_PREVIEWS' + '/' + activityReview.id;
    const data = {
      dp: activity.dp,
    };
    // ApiTask.getInstance().post(endpoint, data, (response) => {
    //   if (onSuccess) {
    //     onSuccess();
    //   }
    // }, (errorType, error) => {
    //   if (onFailure) {
    //     onFailure(errorType, error, activity.id);
    //   }
    // });
  }

  static getApiMobileNumber(mobileNumber, numberCode) {
    return '+'+ numberCode +'-' + mobileNumber;
  }

  static isMobileNumberValid(mobileNumber, length = 9) {
    return mobileNumber && mobileNumber.length === length;
  }

  static isCourierService(business) {
    return business && business.isCourierService;
  }

  static isQuickStore(business) {
    return business && business.businessDeliveryType === STORE_TYPE_EXPRESS;
  }

  static isDriverModeOnly(user) {
    return user && user.isDriverModeEnabled && user.inDriverModeOnly ? true : false;
  }

  static getVideoParams(item) {
    let params = null;
    if (item.feedType === FEED_TYPE_POST) {
      if (item.type === POST_TYPE_CHALLENGE) {
        params = {
          'crId': item.id,
        };
        if (item.activity.dp) {
          params.dp = item.activity.dp;
        }
      }
    } else if (!item.feedType) {
      if (item.type === ACTIVITY_TYPE_SMART_AD
        || item.type === ACTIVITY_TYPE_OFFER
        || item.type === ACTIVITY_TYPE_QUESTION
        || item.type === ACTIVITY_TYPE_OPINION
        || item.type === ACTIVITY_TYPE_VISITOR_OPINION
        || item.type === ACTIVITY_TYPE_DISCOUNT
        || item.type === ACTIVITY_TYPE_CUSTOMER_POST
        || item.type === ACTIVITY_TYPE_CHALLENGE
        || item.type === ACTIVITY_TYPE_PREDICTION) {
        params = {
          'cId': item.id,
        };
        if (item.dp) {
          params.dp = item.dp;
        }
      }
    }
    return params;
  }

  static processItem(item) {
    if (item.feedType === FEED_TYPE_POST) {
      if (item.type === POST_TYPE_CHALLENGE) {
        ApiHelper.processVideoData(item);
      }
    } else if (item.feedType === FEED_TYPE_AD) {
      const ads = item.items;
      if (ads) {
        ads.forEach((ad) => {
          ApiHelper.processVideoData(ad);
        });
      }
    } else if (!item.feedType) {
      ApiHelper.processVideoData(item);
    }
  }

  static processVideoData(item) {
    let imageUrl = item.imageUrls ? item.imageUrls[0] : item.imageUrl;
    if (item.audioUrl) {
      if (!imageUrl) {
        imageUrl = AUDIO_POSTER_IMAGE_URL;
      }
      item.imageUrls = null;
      item.videoUrl = item.audioUrl;
      item.videoStreamUrl = item.audioUrl;
      item.videoDuration = item.audioDuration;
      item.posterUrl = imageUrl;
      item.videoTUrl = imageUrl;
      item.videoWidth = utils.getDeviceWidth();
      item.videoHeight = 300;
      item.isVideoAudioOnly = true;
      item.audioUrl = null;
      item.audioDuration = 0;
    } else if (item.videoUrl) {
      item.videoTUrl = imageUrl;
    }
  }

  static getInfoForAudioUrl(path) {
    if (path) {
      let splits = path.split('/');
      let endPath = splits[splits.length - 1];
      if (endPath.includes('.')) {
        endPath = endPath.split('.')[0];
      }
      splits = endPath.split('_');
      if (splits && splits.length >= 2) {
        const duration = parseInt(splits[1]);
        if (Number.isInteger(duration)) {
          return {duration: duration};
        }
      }
    }
    return null;
  }

  static getInfoForVideoUrl(path) {
    if (path) {
      let splits = path.split('/');
      let endPath = splits[splits.length - 1];
      if (endPath.includes('.')) {
        endPath = endPath.split('.')[0];
      }
      splits = endPath.split('_');
      if (splits && splits.length >= 3) {
        const duration = parseInt(splits[1]);
        if (Number.isInteger(duration)) {
          const size = splits[2];
          const sizeSplits = size.split('x');
          return {
            duration: duration,
            width: parseInt(sizeSplits[0]),
            height: parseInt(sizeSplits[1])
          };
        }
      }
    }
    return null;
  }

  static getInfoForImageUrl(path) {
    if (path) {
      let splits = path.split('/');
      let endPath = splits[splits.length - 1];
      if (endPath.includes('.')) {
        endPath = endPath.split('.')[0];
      }
      splits = endPath.split('_');
      if (splits && splits.length >= 2) {
        const size = splits[1];
        const sizeSplits = size.split('x');
        return {
          width: parseInt(sizeSplits[0]),
          height: parseInt(sizeSplits[1])
        };
      }
    }
    return null;
  }

  static getSlotDefaultValue1(activity, number) {
    if (number < 0) {
      return -1;
    }

    if (activity.type === constants.activity.types.GAME_BRAND_RACE) {
      return 1;
    }
    if (activity.type === constants.activity.types.GAME_POKER) {
      if (number === 0) {
        return 10;
      }
      return number;
    }
    if (activity.type === constants.activity.types.GAME_SPIN_N_DISCOVER) {
      if (number === 0) {
        return 1;
      }
    }
    if (activity.type === constants.activity.types.GAME_BETTING_PIC) {
      if (number === 0) {
        return 10;
      }
    }
    if (activity.type === constants.activity.types.GAME_WHAC_A_MOLE) {
      if (number === 0) {
        return 1;
      }
    }
    if (activity.type === constants.activity.types.GAME_MATCH_THREE) {
      if (number === 0) {
        return 2;
      }
      return 1;
    }

    return 0;
  }

  static getSlotDefaultImageUrl(activity, optionNumber) {
    if (activity.type === constants.activity.types.EXTERNAL_GAMES) {
      if (activity.subType === 1) {
        return gameTapTapShotsInfo().configs.images[optionNumber].url;
      } else if (activity.subType === 2) {
        return gameFreeKicksInfo().configs.images[optionNumber].url;
      } else if (activity.subType === 3) {
        return gamePacManRushInfo().configs.images[optionNumber].url;
      } else if (activity.subType === 4) {
        return gameTrafficGoInfo().configs.images[optionNumber].url;
      } else if (activity.subType === 5) {
        return gameCannonCrazeInfo().configs.images[optionNumber].url;
      } else if (activity.subType === 6) {
        return gameSnakeUpInfo().configs.images[optionNumber].url;
      } else if (activity.subType === 7) {
        return gameArcHunterInfo().configs.images[optionNumber].url;
      } else if (activity.gameInfo.configs) {
        return activity.gameInfo.configs.images[optionNumber].url;
      }
    }

    const poolId = activity.id ? activity.id % 4 : 0;
    if (optionNumber < 0) {
      if (activity.type === constants.activity.types.GAME_BETTING_PIC) {
        return null;
      }
      return APP_GAME_POOL_IMAGES_URI + poolId + '/ic_slot' + (-activity.type) + '.png'; // optional reverse default images
    }

    if (optionNumber < activity.activityConfigs.slotMaxValues) {
      if (activity.type === constants.activity.types.GAME_TIC_TAC_TOE) {
        return APP_GAME_DEFAULT_IMAGES_URI + 'ic_slot_d-o.png'; // mandatory default images
      }
      return APP_GAME_DEFAULT_IMAGES_URI + 'ic_slot_d-' + (optionNumber+1) + '.png'; // mandatory default images
    }
    if (optionNumber < activity.activityConfigs.slotTotalImages) {
      if (activity.type === constants.activity.types.GAME_BETTING_PIC) {
        return null;
      }
      if (activity.type === constants.activity.types.GAME_TIC_TAC_TOE) {
        return APP_GAME_DEFAULT_IMAGES_URI + 'ic_slot_d-x.png'; // optional default images
      }
      let poolImageConst = 100;
      if (activity.type === constants.activity.types.GAME_BRAND_RACE
        || activity.type === constants.activity.types.GAME_POKER
        || activity.type === constants.activity.types.GAME_BETTING_PIC) {
        poolImageConst *= activity.type;
      }
      return APP_GAME_POOL_IMAGES_URI + poolId + '/ic_slot' + (-(poolImageConst + optionNumber)) + '.png'; // optional default images
    }
  }

  static getSlotDefaultCardImageUrl(activityType) {
    if (activityType === constants.activity.types.GAME_PUZZLE) {
      return APP_GAME_DEFAULT_IMAGES_URI + 'ic_slot_d-p4.png';
    }
    if (activityType === constants.activity.types.GAME_PUZZLE_SLIDE) {
      return APP_GAME_DEFAULT_IMAGES_URI + 'ic_slot_d-p.png';
    }
    return APP_GAME_DEFAULT_IMAGES_URI + 'ic_slot_d-f.png';
  }

  static getSlotDefaultCardRevealImageUrl() {
    return APP_GAME_DEFAULT_IMAGES_URI + 'ic_slot_d-b.png';
  }

  static getActivityDefaultImageUrl(activityType, gameInfo) {
    if (activityType === constants.activity.types.EXTERNAL_GAMES && gameInfo) {
      return gameInfo.imageUrl;
    }
    return `${API_ASSET_URI}/images/activity/img-${activityType}.png`;
  }

  static getUserDefaultImageUrl() {
    return `${API_ASSET_URI}/images/ic_user_default.png`;
  }

  static getActivityDefaultTitle(name, activityType, gameInfo, intl) {
    if (activityType === constants.activity.types.EXTERNAL_GAMES && gameInfo) {
      return name + ' '+ gameInfo.name;
    }
    return name + ' '+ intl.formatMessage({id: `activity.manage.${activityType}.title`});
  }

  static isActivityImageDefault(imageUrl, activityType) {
    return imageUrl && imageUrl == `${API_ASSET_URI}/images/activity/img-${activityType}.png`;
  }
}

export const getErrorFromResponse = (response, intl) => {
  let error = null;
  if (response.data && response.data.error) {
    error = response.data.error;
  } else if (response.error === 'NETWORK_ERROR') {
    error = intl.formatMessage({id: 'generic.server_not_reachable'});
  } else {
    error = intl.formatMessage({id: 'generic.server_error_msg'});
  }
  return error;
}

export const isCodeValid = (code) => {
  return code && code.trim().length === 4;
}
