import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {hot} from 'react-hot-loader/root';

import Routes from './Routes';
import auth from '../utils/auth';
import {fetchProfile} from '../actions/profileActions'
import {checkForInstallPrompt} from "../utils/AppInstallHelper";
import {changeBodyColor, withMediaQueries} from "src/styles/Styles";
import {ColorBackground, ColorBackgroundXS} from "src/styles/Colors";
import {PathJoin, PathLogin} from "src/js/components/routes/NavHelper";
import LogRocket from 'logrocket';
import {APP_TYPE, APP_TYPE_PROD} from "src/js/utils/services";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.backgroundColor = null;
    document.documentElement.style.setProperty('--recaptcha-hidden','hidden');
  }

  componentDidMount () {
    if (APP_TYPE === APP_TYPE_PROD) {
      LogRocket.init('kqnm2f/rab7');
    }
    if (auth.isAuthenticated()) {
      if (APP_TYPE === APP_TYPE_PROD) {
        LogRocket.identify(auth.user.id, {
          name: auth.user.rab7Number,
        });
      }
      this.props.fetchProfile();
    }

    checkForInstallPrompt();
    this.updateBgColor();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateBgColor();
  }

  updateBgColor() {
    const isXS = window.innerWidth <= 600;
    let bgColor = ColorBackground;
    if (isXS) {
      bgColor = ColorBackgroundXS;
    } else {
      const path = location.pathname;
      if (path.startsWith(PathLogin)
        || path.startsWith(PathJoin)
        || path.startsWith('/recoverPassword')
        || path.startsWith('/verifyAccount')
        || path.startsWith('/redeemOffer')
        || path.startsWith('/account')) {
        bgColor = ColorBackgroundXS;
      }
    }
    if (this.backgroundColor !== bgColor) {
      this.backgroundColor = bgColor;
      changeBodyColor(this.backgroundColor);
    }
  }

  render() {
    return (
      <div className='app-container'>
        <Routes />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  fetchProfile: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: () => dispatch(fetchProfile()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withMediaQueries([
    ['isXS', theme => theme.breakpoints.down('xs'), {defaultMatches: true}],
  ])(hot(App))
);
