import * as types from '../constants/actionTypes';

let loadingCounter = 0;

export default function loadingReducer(state, action) {

  switch (action.type) {
    case types.DO_REGISTRATION_VERIFICATION:
    case types.DO_REGISTRATION_VERIFICATION_CODE:
    case types.DO_LOGIN:
    case types.DO_LOGIN_MFA:
    case types.DO_SEND_OTP:
    case types.DO_RESET_PASSWORD:
    case types.DO_VERIFY_ACCOUNT:
    case types.DO_REGISTER:
    case types.DO_REGISTER_GENERATE_OTP:
    case types.SHOW_GEO_LOCATION_LOADING:
    case types.FETCH_ACTIVITY_INSIGHT_CONFIGS:

    case types.DELETE_NOTIFICATION:
    case types.FETCH_HELP:
    case types.FETCH_CATEGORIES:
    case types.FETCH_BANKS:
    case types.FETCH_BRANCHES:
    case types.FETCH_BRANCH:
    case types.DO_REDEEM_CODE:
    case types.DELETE_BRANCH:
    case types.CREATE_BRANCH:
    case types.UPDATE_BRANCH:
    case types.TOGGLE_BRANCH_STATE:
    case types.FETCH_GIFTS:
    case types.FETCH_GIFT:
    case types.DELETE_GIFT:
    case types.CREATE_GIFT:
    case types.TOGGLE_GIFT_STATE:
    case types.FETCH_COMPANY_LEVELS:
    case types.FETCH_EARN_POINT_FORMULAS:
    case types.FETCH_EARN_POINT_FORMULA:
    case types.DELETE_EARN_POINT_FORMULA:
    case types.CREATE_EARN_POINT_FORMULA:
    case types.TOGGLE_EARN_POINT_FORMULA_STATE:
    case types.FETCH_REDEEM_POINT_FORMULAS:
    case types.FETCH_REDEEM_POINT_FORMULA:
    case types.DELETE_REDEEM_POINT_FORMULA:
    case types.CREATE_REDEEM_POINT_FORMULA:
    case types.TOGGLE_REDEEM_POINT_FORMULA_STATE:
    case types.FETCH_EMPLOYEES:
    case types.FETCH_EMPLOYEE:
    case types.DELETE_EMPLOYEE:
    case types.DELETE_EMPLOYEE_INVITE:
    case types.CREATE_EMPLOYEE_INVITE:
    case types.TOGGLE_EMPLOYEE_STATE:
    case types.FETCH_POSTS:
    case types.FETCH_POST:
    case types.DELETE_POST:
    case types.CREATE_POST_LIKE:
    case types.FETCH_POST_COMMENTS:
    case types.CREATE_POST_COMMENT:
    case types.DELETE_POST_COMMENT:
    case types.CREATE_POST_COMMENT_LIKE:
    case types.TOGGLE_ACTIVITY_STATE:
    case types.TOGGLE_GAME_STATE:
    // case types.FETCH_ACTIVITIES:
    // case types.FETCH_FUN_ACTIVITIES:
    case types.FETCH_ACTIVITY:
    case types.FETCH_FUN_ACTIVITY:
    case types.FETCH_GAME:
    case types.CREATE_ACTIVITY:
    case types.UPDATE_ACTIVITY:
    case types.UPDATE_ACTIVITY_DISPLAY_CRITERIA:
    case types.DELETE_ACTIVITY:
    case types.DELETE_GAME:
    case types.CREATE_ACTIVITY_LIKE:
    case types.FETCH_ACTIVITY_COMMENTS:
    case types.CREATE_ACTIVITY_COMMENT:
    case types.DELETE_ACTIVITY_COMMENT:
    case types.CREATE_ACTIVITY_COMMENT_LIKE:
    case types.FETCH_EMPLOYEE_CHATS:
    case types.FETCH_EMPLOYEE_MESSAGES:
    case types.CREATE_EMPLOYEE_MESSAGE:
    case types.FETCH_CUSTOMER_CHATS:
    case types.FETCH_CUSTOMER_MESSAGES:
    case types.CREATE_CUSTOMER_MESSAGE:
    case types.FETCH_SUPPORT_MESSAGES:
    case types.CREATE_SUPPORT_MESSAGE:
    // case types.FETCH_PROFILE:
    case types.FETCH_HOME:
    case types.DO_CHANGE_SETTINGS:
    case types.DO_FETCH_MOBILE_VERIFICATION:
    case types.DO_CHANGE_MOBILE_NUMBER:
    case types.FETCH_INSIGHTS_CAMPAIGNS:
    case types.FETCH_INSIGHTS_SALES:
    case types.FETCH_INSIGHTS_EMPLOYEES:
    case types.FETCH_INSIGHTS_PROFILE:
    case types.FETCH_INSIGHTS_PRODUCTS:
    case types.FETCH_TRANSACTIONS:
    case types.FETCH_USER_BALANCES:
    case types.FETCH_SYSTEM_USERS:
    case types.FETCH_SYSTEM_USER:
    case types.DELETE_SYSTEM_USER:
    case types.CREATE_SYSTEM_USER:
    case types.TOGGLE_SYSTEM_USER_STATE:
    case types.FETCH_TRANSFER_TRANSACTIONS:
    case types.FETCH_ACTIVITY_QUESTIONS:
    case types.FETCH_ACTIVITY_QUESTION_OPTIONS:
    case types.FETCH_FOCUS_AREA:
    case types.FETCH_INSIGHT_CHARTS:
    case types.FETCH_INSIGHT_FILTERS:
    // case types.FETCH_AGE_GROUP:
    // case types.FETCH_NATIONALITIES:
    case types.FETCH_PRODUCT_CATEGORIES:
    case types.FETCH_PRODUCT_CATEGORY:
    case types.DELETE_PRODUCT_CATEGORY:
    case types.CREATE_PRODUCT_CATEGORY:
    case types.TOGGLE_PRODUCT_CATEGORY_STATE:
    case types.FETCH_PRODUCT_ITEMS:
    case types.FETCH_PRODUCT_ITEM:
    case types.DELETE_PRODUCT_ITEM:
    case types.CREATE_PRODUCT_ITEM:
    case types.TOGGLE_PRODUCT_ITEM_STATE:
    case types.UPDATE_ALLOW_BALANCE_TRANSFER:
    case types.FETCH_ACTIVITY_RATINGS:
    case types.FETCH_ACTIVITY_ANSWERS:
    case types.EMPLOYEE_BROADCAST:
    {
      if (!action.isLoadingDisabled) {
        loadingCounter += 1;
      }
    }
    break;

    case types.DO_REGISTRATION_VERIFICATION_SUCCESS:
    case types.DO_REGISTRATION_VERIFICATION_FAILURE:
    case types.DO_REGISTRATION_VERIFICATION_CODE_SUCCESS:
    case types.DO_REGISTRATION_VERIFICATION_CODE_FAILURE:
    case types.DO_SEND_OTP_SUCCESS:
    case types.DO_SEND_OTP_FAILURE:
    case types.DO_REGISTER_SUCCESS:
    case types.DO_REGISTER_FAILURE:
    case types.DO_REGISTER_GENERATE_OTP_SUCCESS:
    case types.DO_REGISTER_GENERATE_OTP_FAILURE:
    case types.DO_RESET_PASSWORD_SUCCESS:
    case types.DO_RESET_PASSWORD_FAILURE:
    case types.DO_VERIFY_ACCOUNT_SUCCESS:
    case types.DO_VERIFY_ACCOUNT_FAILURE:
    case types.HIDE_GEO_LOCATION_LOADING:
    case types.FETCH_ACTIVITY_INSIGHT_CONFIGS_SUCCESS:
    case types.FETCH_ACTIVITY_INSIGHT_CONFIGS_FAILURE:

    case types.DELETE_NOTIFICATION_SUCCESS:
    case types.DELETE_NOTIFICATION_FAILURE:
    case types.FETCH_HELP_SUCCESS:
    case types.FETCH_HELP_FAILURE:
    case types.FETCH_CATEGORIES_SUCCESS:
    case types.FETCH_CATEGORIES_FAILURE:
    case types.FETCH_BANKS_SUCCESS:
    case types.FETCH_BANKS_FAILURE:
    case types.FETCH_BRANCHES_SUCCESS:
    case types.FETCH_BRANCHES_FAILURE:
    case types.FETCH_BRANCH_SUCCESS:
    case types.FETCH_BRANCH_FAILURE:
    case types.CREATE_BRANCH_SUCCESS:
    case types.CREATE_BRANCH_FAILURE:
    case types.UPDATE_BRANCH_SUCCESS:
    case types.UPDATE_BRANCH_FAILURE:
    case types.DELETE_BRANCH_SUCCESS:
    case types.DELETE_BRANCH_FAILURE:
    case types.TOGGLE_BRANCH_STATE_SUCCESS:
    case types.TOGGLE_BRANCH_STATE_FAILURE:
    case types.FETCH_GIFTS_SUCCESS:
    case types.FETCH_GIFTS_FAILURE:
    case types.FETCH_GIFT_SUCCESS:
    case types.FETCH_GIFT_FAILURE:
    case types.CREATE_GIFT_SUCCESS:
    case types.CREATE_GIFT_FAILURE:
    case types.DELETE_GIFT_SUCCESS:
    case types.DELETE_GIFT_FAILURE:
    case types.TOGGLE_GIFT_STATE_SUCCESS:
    case types.TOGGLE_GIFT_STATE_FAILURE:
    case types.FETCH_COMPANY_LEVELS_SUCCESS:
    case types.FETCH_COMPANY_LEVELS_FAILURE:
    case types.FETCH_EARN_POINT_FORMULAS_SUCCESS:
    case types.FETCH_EARN_POINT_FORMULAS_FAILURE:
    case types.FETCH_EARN_POINT_FORMULA_SUCCESS:
    case types.FETCH_EARN_POINT_FORMULA_FAILURE:
    case types.CREATE_EARN_POINT_FORMULA_SUCCESS:
    case types.CREATE_EARN_POINT_FORMULA_FAILURE:
    case types.DELETE_EARN_POINT_FORMULA_SUCCESS:
    case types.DELETE_EARN_POINT_FORMULA_FAILURE:
    case types.TOGGLE_EARN_POINT_FORMULA_STATE_SUCCESS:
    case types.TOGGLE_EARN_POINT_FORMULA_STATE_FAILURE:
    case types.FETCH_REDEEM_POINT_FORMULAS_SUCCESS:
    case types.FETCH_REDEEM_POINT_FORMULAS_FAILURE:
    case types.FETCH_REDEEM_POINT_FORMULA_SUCCESS:
    case types.FETCH_REDEEM_POINT_FORMULA_FAILURE:
    case types.CREATE_REDEEM_POINT_FORMULA_SUCCESS:
    case types.CREATE_REDEEM_POINT_FORMULA_FAILURE:
    case types.DELETE_REDEEM_POINT_FORMULA_SUCCESS:
    case types.DELETE_REDEEM_POINT_FORMULA_FAILURE:
    case types.TOGGLE_REDEEM_POINT_FORMULA_STATE_SUCCESS:
    case types.TOGGLE_REDEEM_POINT_FORMULA_STATE_FAILURE:
    case types.FETCH_EMPLOYEES_SUCCESS:
    case types.FETCH_EMPLOYEES_FAILURE:
    case types.FETCH_EMPLOYEE_SUCCESS:
    case types.FETCH_EMPLOYEE_FAILURE:
    case types.CREATE_EMPLOYEE_INVITE_SUCCESS:
    case types.CREATE_EMPLOYEE_INVITE_FAILURE:
    case types.DELETE_EMPLOYEE_SUCCESS:
    case types.DELETE_EMPLOYEE_FAILURE:
    case types.DELETE_EMPLOYEE_INVITE_SUCCESS:
    case types.DELETE_EMPLOYEE_INVITE_FAILURE:
    case types.TOGGLE_EMPLOYEE_STATE_SUCCESS:
    case types.TOGGLE_EMPLOYEE_STATE_FAILURE:
    case types.FETCH_POSTS_SUCCESS:
    case types.FETCH_POSTS_FAILURE:
    case types.FETCH_POST_SUCCESS:
    case types.FETCH_POST_FAILURE:
    case types.DELETE_POST_SUCCESS:
    case types.DELETE_POST_FAILURE:
    case types.CREATE_POST_LIKE_SUCCESS:
    case types.CREATE_POST_LIKE_FAILURE:
    case types.FETCH_POST_COMMENTS_SUCCESS:
    case types.FETCH_POST_COMMENTS_FAILURE:
    case types.CREATE_POST_COMMENT_SUCCESS:
    case types.CREATE_POST_COMMENT_FAILURE:
    case types.DELETE_POST_COMMENT_SUCCESS:
    case types.DELETE_POST_COMMENT_FAILURE:
    case types.CREATE_POST_COMMENT_LIKE_SUCCESS:
    case types.CREATE_POST_COMMENT_LIKE_FAILURE:
    case types.TOGGLE_ACTIVITY_STATE_SUCCESS:
    case types.TOGGLE_ACTIVITY_STATE_FAILURE:
    case types.TOGGLE_GAME_STATE_SUCCESS:
    case types.TOGGLE_GAME_STATE_FAILURE:
    // case types.FETCH_ACTIVITIES_SUCCESS:
    // case types.FETCH_ACTIVITIES_FAILURE:
    // case types.FETCH_FUN_ACTIVITIES_SUCCESS:
    // case types.FETCH_FUN_ACTIVITIES_FAILURE:
    case types.FETCH_ACTIVITY_SUCCESS:
    case types.FETCH_ACTIVITY_FAILURE:
    case types.FETCH_FUN_ACTIVITY_SUCCESS:
    case types.FETCH_FUN_ACTIVITY_FAILURE:
    case types.FETCH_GAME_SUCCESS:
    case types.FETCH_GAME_FAILURE:
    case types.CREATE_ACTIVITY_SUCCESS:
    case types.CREATE_ACTIVITY_FAILURE:
    case types.UPDATE_ACTIVITY_SUCCESS:
    case types.UPDATE_ACTIVITY_FAILURE:
    case types.UPDATE_ACTIVITY_DISPLAY_CRITERIA_SUCCESS:
    case types.UPDATE_ACTIVITY_DISPLAY_CRITERIA_FAILURE:
    case types.DELETE_ACTIVITY_SUCCESS:
    case types.DELETE_ACTIVITY_FAILURE:
    case types.DELETE_GAME_SUCCESS:
    case types.DELETE_GAME_FAILURE:
    case types.CREATE_ACTIVITY_LIKE_SUCCESS:
    case types.CREATE_ACTIVITY_LIKE_FAILURE:
    case types.FETCH_ACTIVITY_COMMENTS_SUCCESS:
    case types.FETCH_ACTIVITY_COMMENTS_FAILURE:
    case types.CREATE_ACTIVITY_COMMENT_SUCCESS:
    case types.CREATE_ACTIVITY_COMMENT_FAILURE:
    case types.DELETE_ACTIVITY_COMMENT_SUCCESS:
    case types.DELETE_ACTIVITY_COMMENT_FAILURE:
    case types.CREATE_ACTIVITY_COMMENT_LIKE_SUCCESS:
    case types.CREATE_ACTIVITY_COMMENT_LIKE_FAILURE:
    case types.FETCH_EMPLOYEE_CHATS_SUCCESS:
    case types.FETCH_EMPLOYEE_CHATS_FAILURE:
    case types.FETCH_EMPLOYEE_MESSAGES_SUCCESS:
    case types.FETCH_EMPLOYEE_MESSAGES_FAILURE:
    case types.CREATE_EMPLOYEE_MESSAGE_SUCCESS:
    case types.CREATE_EMPLOYEE_MESSAGE_FAILURE:
    case types.FETCH_CUSTOMER_CHATS_SUCCESS:
    case types.FETCH_CUSTOMER_CHATS_FAILURE:
    case types.FETCH_CUSTOMER_MESSAGES_SUCCESS:
    case types.FETCH_CUSTOMER_MESSAGES_FAILURE:
    case types.CREATE_CUSTOMER_MESSAGE_SUCCESS:
    case types.CREATE_CUSTOMER_MESSAGE_FAILURE:
    case types.FETCH_SUPPORT_MESSAGES_SUCCESS:
    case types.FETCH_SUPPORT_MESSAGES_FAILURE:
    case types.CREATE_SUPPORT_MESSAGE_SUCCESS:
    case types.CREATE_SUPPORT_MESSAGE_FAILURE:
    // case types.FETCH_PROFILE_SUCCESS:
    // case types.FETCH_PROFILE_FAILURE:
    case types.FETCH_HOME_SUCCESS:
    case types.FETCH_HOME_FAILURE:
    case types.DO_CHANGE_SETTINGS_SUCCESS:
    case types.DO_CHANGE_SETTINGS_FAILURE:
    case types.DO_FETCH_MOBILE_VERIFICATION_SUCCESS:
    case types.DO_FETCH_MOBILE_VERIFICATION_FAILURE:
    case types.DO_CHANGE_MOBILE_NUMBER_SUCCESS:
    case types.DO_CHANGE_MOBILE_NUMBER_FAILURE:
    case types.FETCH_INSIGHTS_CAMPAIGNS_SUCCESS:
    case types.FETCH_INSIGHTS_CAMPAIGNS_FAILURE:
    case types.FETCH_INSIGHTS_SALES_SUCCESS:
    case types.FETCH_INSIGHTS_SALES_FAILURE:
    case types.FETCH_INSIGHTS_EMPLOYEES_SUCCESS:
    case types.FETCH_INSIGHTS_EMPLOYEES_FAILURE:
    case types.FETCH_INSIGHTS_PRODUCTS_SUCCESS:
    case types.FETCH_INSIGHTS_PRODUCTS_FAILURE:
    case types.FETCH_INSIGHTS_PROFILE_SUCCESS:
    case types.FETCH_INSIGHTS_PROFILE_FAILURE:
    case types.FETCH_TRANSACTIONS_SUCCESS:
    case types.FETCH_TRANSACTIONS_FAILURE:
    case types.FETCH_USER_BALANCES_SUCCESS:
    case types.FETCH_USER_BALANCES_FAILURE:
    case types.FETCH_SYSTEM_USERS_SUCCESS:
    case types.FETCH_SYSTEM_USERS_FAILURE:
    case types.FETCH_SYSTEM_USER_SUCCESS:
    case types.FETCH_SYSTEM_USER_FAILURE:
    case types.CREATE_SYSTEM_USER_SUCCESS:
    case types.CREATE_SYSTEM_USER_FAILURE:
    case types.DELETE_SYSTEM_USER_SUCCESS:
    case types.DELETE_SYSTEM_USER_FAILURE:
    case types.TOGGLE_SYSTEM_USER_STATE_SUCCESS:
    case types.TOGGLE_SYSTEM_USER_STATE_FAILURE:
    case types.FETCH_TRANSFER_TRANSACTIONS_SUCCESS:
    case types.FETCH_TRANSFER_TRANSACTIONS_FAILURE:
    case types.FETCH_ACTIVITY_QUESTIONS_SUCCESS:
    case types.FETCH_ACTIVITY_QUESTIONS_FAILURE:
    case types.FETCH_ACTIVITY_QUESTION_OPTIONS_SUCCESS:
    case types.FETCH_ACTIVITY_QUESTION_OPTIONS_FAILURE:
    case types.FETCH_FOCUS_AREA_SUCCESS:
    case types.FETCH_FOCUS_AREA_FAILURE:
    case types.FETCH_INSIGHT_CHARTS_SUCCESS:
    case types.FETCH_INSIGHT_CHARTS_FAILURE:
    case types.FETCH_INSIGHT_FILTERS_SUCCESS:
    case types.FETCH_INSIGHT_FILTERS_FAILURE:
    // case types.FETCH_AGE_GROUP_FAILURE:
    // case types.FETCH_AGE_GROUP_SUCCESS:
    // case types.FETCH_NATIONALITIES_SUCCESS:
    // case types.FETCH_NATIONALITIES_FAILURE:
    case types.FETCH_PRODUCT_CATEGORIES_SUCCESS:
    case types.FETCH_PRODUCT_CATEGORIES_FAILURE:
    case types.FETCH_PRODUCT_CATEGORY_SUCCESS:
    case types.FETCH_PRODUCT_CATEGORY_FAILURE:
    case types.CREATE_PRODUCT_CATEGORY_SUCCESS:
    case types.CREATE_PRODUCT_CATEGORY_FAILURE:
    case types.DELETE_PRODUCT_CATEGORY_SUCCESS:
    case types.DELETE_PRODUCT_CATEGORY_FAILURE:
    case types.TOGGLE_PRODUCT_CATEGORY_STATE_SUCCESS:
    case types.TOGGLE_PRODUCT_CATEGORY_STATE_FAILURE:
    case types.FETCH_PRODUCT_ITEMS_SUCCESS:
    case types.FETCH_PRODUCT_ITEMS_FAILURE:
    case types.FETCH_PRODUCT_ITEM_SUCCESS:
    case types.FETCH_PRODUCT_ITEM_FAILURE:
    case types.CREATE_PRODUCT_ITEM_SUCCESS:
    case types.CREATE_PRODUCT_ITEM_FAILURE:
    case types.DELETE_PRODUCT_ITEM_SUCCESS:
    case types.DELETE_PRODUCT_ITEM_FAILURE:
    case types.TOGGLE_PRODUCT_ITEM_STATE_SUCCESS:
    case types.TOGGLE_PRODUCT_ITEM_STATE_FAILURE:
    case types.UPDATE_ALLOW_BALANCE_TRANSFER_SUCCESS:
    case types.UPDATE_ALLOW_BALANCE_TRANSFER_FAILURE:
    case types.FETCH_ACTIVITY_ANSWERS_FAILURE:
    case types.FETCH_ACTIVITY_ANSWERS_SUCCESS:
    case types.FETCH_ACTIVITY_RATINGS_SUCCESS:
    case types.FETCH_ACTIVITY_RATINGS_FAILURE:
    case types.EMPLOYEE_BROADCAST_FAILURE:
    case types.EMPLOYEE_BROADCAST_SUCCESS:
    {
      if (!action.isLoadingDisabled) {
        loadingCounter -= 1;
      }
    }
    break;

    case types.DO_LOGOUT:
      loadingCounter = 1;
      break;
    case '@@router/LOCATION_CHANGE':
    case types.DO_LOGIN_SUCCESS:
    case types.DO_LOGIN_FAILURE:
    case types.DO_LOGIN_MFA_SUCCESS:
    case types.DO_LOGIN_MFA_FAILURE:
    case types.DO_REDEEM_CODE_SUCCESS:
    case types.DO_REDEEM_CODE_FAILURE:
    case types.RESET_LOADING:
      loadingCounter = 0;
  }

  return (loadingCounter > 0) ? true : false;

}
