import {cloneDeep} from "lodash";

export default class ValueHelper {

  static getDisplayValue(value, decimal = 0, withM = true, withK = true) {
    if (value === null) {
      return null;
    }
    value = parseFloat(value);
    if (isNaN(value)) {
      return null;
    }

    if (withM || withK) {
      if (value >= 1000000) {
        value =  value / 1000000;
        return ValueHelper.toFixedCommaFormatted(value, decimal) + "M";
      } else if (withK && value >= 1000) {
        value =  value / 1000;
        return ValueHelper.toFixedCommaFormatted(value, decimal) + "K";
      }
    }
    return ValueHelper.toFixedCommaFormatted(value, decimal);
  }

  static toFixedCommaFormatted(value, decimal) {
    const valueStr = ''+ value;
    const splits = valueStr.split('.');
    let decimalLength = 0;
    if (splits.length > 1) {
      decimalLength = splits[1].length;
    }
    decimal = Math.min(decimal, decimalLength);
    let formattedValue = value.toFixed(decimal);
    formattedValue = formattedValue.toLocaleString();
    return formattedValue;
  }

  static round(value, decimal) {
    const num = Math.pow(10, decimal);
    return Math.round(value * num) / num;
  }

  static commaFormatted(amount) {
    amount = "" + amount;
    var delimiter = ","; // replace comma if desired
    var a = amount.split('.', 2);
    var d = a[1] ? a[1] : "";
    var i = parseInt(a[0]);
    if(isNaN(i)) { return ''; }
    var minus = '';
    if(i < 0) { minus = '-'; }
    i = Math.abs(i);
    var n = new String(i);
    var a = [];
    while(n.length > 3) {
      var nn = n.substr(n.length-3);
      a.unshift(nn);
      n = n.substr(0,n.length-3);
    }
    if(n.length > 0) { a.unshift(n); }
    n = a.join(delimiter);
    if(d.length < 1) { amount = n; }
    else { amount = n + '.' + d; }
    amount = minus + amount;
    return amount;
  }

  static getEnglishValue(value) {
    if (value) {
      if (!(typeof value === 'string' || value instanceof String)) {
        value = '' + value;
      }
      return value.replace(/[\u0660-\u0669]/g, function (c) {
        return c.charCodeAt(0) - 0x0660;
      }).replace(/[\u066B]/g, function (c) {
        return 0x066B;
      });
    }
    // .replace(/[\u06f0-\u06f9]/g, function (c) {
    //     return c.charCodeAt(0) - 0x06f0;
    // });
    return value;
  }

  static getDecimalValue(value, fraction = 0) {
    if (value && value.length > 0) {
      value = value.replace(/[^0-9.\u0660-\u0669\u066B]/g, '');
      if (fraction <= 0) {
        return value.replace(/\./g, '');
      }
      const firstDotIndex = value.indexOf('.');
      if (firstDotIndex !== -1) {
        value = value.substr(0, firstDotIndex + 1) + value.slice(firstDotIndex + 1).replace(/\./g, '');
        if (value.length > firstDotIndex + 1 + fraction) {
          value = value.substr(0, firstDotIndex + 1 + fraction);
        }
      }
      return value;
    }
    return null;
  }

  static isFraction(value) {
    if (value && value.length > 0) {
      value = value.replace(/[^0-9.^\u0660-\u0669\u066B]/g, '');
      const firstDotIndex = value.indexOf('.');
      if (firstDotIndex !== -1) {
        return true;
      }
    }
    return false;
  }
}

export const textFieldIntegerOnKeyDown = (event) => {
  if (event.keyCode === 69
    || event.keyCode === 107
    || event.keyCode === 109
    || event.keyCode === 110
    || event.keyCode === 187
    || event.keyCode === 189
    || event.keyCode === 190
  ) {
    event.preventDefault();
  }
}

export const textFieldNumberOnKeyDown = (event) => {
  if (event.keyCode === 69
    || event.keyCode === 107
    || event.keyCode === 109
    || event.keyCode === 187
    || event.keyCode === 189
  ) {
    event.preventDefault();
  }
}

export const getInitialFiles = (files, url, type, thumbnail) => {
  let initialFiles = null;
  if (files && files.length > 0) {
    initialFiles = cloneDeep(files);
  } else if (url) {
    initialFiles = [{url: url, type: type, thumbnail: thumbnail}];
  }
  if (!initialFiles) {
    initialFiles = [];
  }
  return initialFiles;
}

export const roundFraction = (number, decimal) => {
  const multiple = Math.pow(10, decimal);
  return Math.round((number + Number.EPSILON) * multiple) / multiple;
}
