import * as types from '../constants/actionTypes';
import { wrapCall } from '../utils/serviceUtils';
import { api } from '../utils/services';
import constants from '../constants';
import ValueHelper from "../utils/ValueHelper";
import auth from "../utils/auth";
import {logDebug} from "../utils/AppLog";

export const resetLoginErrMsg = () => {
  return {
    type: types.RESET_LOGIN_ERROR_MESSAGE
  }
};

export const doLoginGoogle = (data) => {
  data.device = {
    platform: constants.app.platform,
    appVersion: constants.app.appVersion
  };
  const user = auth.getUser();
  const isUserGuest = user && !user.registerType && auth.getToken();
  return wrapCall(
    api.post(isUserGuest ? `user/auth/userSG` : `auth/userSG`, data),
    types.DO_LOGIN,
    {user: data.code, moveToPath: data.moveToPath}
  );
};

export const doLoginApple = (data) => {
  data.device = {
    platform: constants.app.platform,
    appVersion: constants.app.appVersion
  };
  const user = auth.getUser();
  const isUserGuest = user && !user.registerType && auth.getToken();
  return wrapCall(
    api.post(isUserGuest ? `user/auth/userSA` : `auth/userSA`, data),
    types.DO_LOGIN,
    {user: data.code, moveToPath: data.moveToPath}
  );
};

export const doLogin = (data) => {
  const user = auth.getUser();
  const isUserGuest = user && !user.registerType && auth.getToken();
  return wrapCall(
    api.post(isUserGuest ? `user/auth/user` : `auth/user`, {
      email: data.email,
      password: data.password,
      sToken: data.sToken,
      code: data.code,
      activityId: data.activityId,
      device: {
        platform: constants.app.platform,
        appVersion: constants.app.appVersion
      }
    }),
    types.DO_LOGIN,
    {user: data.email, moveToPath: data.moveToPath}
  );
};

export const doJoinInline = (data) => {
  return wrapCall(
    api.post(`user/auth/userJI`, {
      email: data.email,
      sToken: data.sToken,
      code: data.code,
      name: data.name,
      gender: data.gender,
      birthday: data.birthday,
      activityId: data.activityId,
      device: {
        platform: constants.app.platform,
        appVersion: constants.app.appVersion
      }
    }),
    types.DO_LOGIN_PLAY_JOIN,
    {user: data.email, skipMove: true}
  );
};

export const doJoinInlineComplete = (data) => {
  return {
    type: types.DO_LOGIN_PLAY_JOIN_SUCCESS2,
    data: data,
    skipMove: true
  }
};

export const doLoginGuest = (data) => {
  return wrapCall(
    api.post(`auth/userG`, {
      sToken: data.sToken,
      code: data.code,
      device: {
        platform: constants.app.platform,
        appVersion: constants.app.appVersion
      }
    }),
    types.DO_LOGIN,
    {user: data.email, skipMove: true}
  );
};

export const doLoginMFA = (numberCode, mobileNumber, businessId, verificationCode) => {
  return wrapCall(
    api.post(`login/user`, {
      mobileNumber: '+'+ numberCode +'-'+ ValueHelper.getEnglishValue(mobileNumber),
      businessId: businessId,
      verificationCode: ValueHelper.getEnglishValue(verificationCode),
      device: {
        platform: constants.app.platform,
        appVersion: constants.app.appVersion
      }
    }),
    types.DO_LOGIN_MFA,
    {user: mobileNumber}
  );
};

export const doLogout = () => {
  return wrapCall(
    api.get(`user/logout`),
    types.DO_LOGOUT,
  );
};

export const doSendOtp = (email) => {
  return wrapCall(
    api.post(`recoverPassword/business`, {email}),
    types.DO_SEND_OTP
  );
};

export const doResetPassword = (email, verificationCode, newPassword) => {
  return wrapCall(
    api.post(`resetPassword/business`, {email, verificationCode, newPassword}),
    types.DO_RESET_PASSWORD
  );
};
