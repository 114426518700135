import {Scene} from 'phaser';
import {EventBus} from "src/gameTapTapShots/EventBus";
import {logDebug} from "src/js/utils/AppLog";

export class MainMenu extends Scene {
  constructor() {
    super('MainMenu');
  }

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
  }

  create() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    if (this.audioGameBg) {
      this.audioGameBg.stop();
      this.audioGameBg = null;
      EventBus.off('mute', this.onEventMute);
    }
    this.audioGameBg = this.sound.add('audio_game_bg', {loop: true});
    this.audioGameBg.play();
    this.audioGameBg.setMute(this.game.options.mute);
    EventBus.on('mute', this.onEventMute);

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const adImage = this.add.image(gameWidth / 2, (gameWidth - 48) / 2 + 180, 'adImage1');
    adImage.setDisplaySize(gameWidth - 48, gameWidth - 48);
    adImage.setAlpha(0.8);
    // if (this.game.options && this.game.options.canPlay) {
    //   adImage.setAlpha(0.2);
    // }

    // this.add.text(gameWidth/2, gameHeight/2-360, 'TAP TAP', {
    //     // fontFamily: 'Arial',
    //     fontSize: 105, color: '#ffffff',
    //     stroke: '#48464F', strokeThickness: 12,
    //     align: 'center'
    // }).setOrigin(0.5);
    // this.add.text(gameWidth/2, gameHeight/2-240, 'SHOTS', {
    //     // fontFamily: 'Arial',
    //     fontSize: 150, color: '#ffffff',
    //     stroke: '#48464F', strokeThickness: 12,
    //     align: 'center'
    // }).setOrigin(0.5);

    const hoopPosition = this.getHoopPosition();

    // this.platforms = this.physics.add.staticGroup();
    // this.platforms.create(gameWidth, gameHeight-10, 'ground');
    // this.platforms.create(0, gameHeight-10, 'ground');

    this.hoop1 = this.add.image(hoopPosition.hoop1.x, hoopPosition.hoop1.y, 'hoop1');
    this.hoop2 = this.add.image(hoopPosition.hoop2.x, hoopPosition.hoop2.y, 'hoop2');
    this.hoop3 = this.add.image(hoopPosition.hoop3.x, hoopPosition.hoop3.y, 'hoop3');
    this.hoop5 = this.add.image(hoopPosition.hoop5.x, hoopPosition.hoop5.y, 'hoop5');
    this.hoop6 = this.add.image(hoopPosition.hoop6.x, hoopPosition.hoop6.y, 'hoop6');

    this.hoopb1 = this.add.image(hoopPosition.hoopb1.x, hoopPosition.hoopb1.y, 'hoopb1');
    this.hoopb2 = this.add.image(hoopPosition.hoopb2.x, hoopPosition.hoopb2.y, 'hoopb2');
    this.hoopb3 = this.add.image(hoopPosition.hoopb3.x, hoopPosition.hoopb3.y, 'hoopb3');

    this.hoop4 = this.physics.add.staticSprite(hoopPosition.hoop4.x, hoopPosition.hoop4.y, 'hoop4');
    this.hoopNet = this.physics.add.staticSprite(hoopPosition.hoopNet.x, hoopPosition.hoopNet.y, 'hoopNet');

    this.add.image(gameWidth * 0.4, gameHeight * 0.5, 'ballC');

    const stringValTapToDunk = this.game.options.locale && this.game.options.locale == 'ar' ? 'ضغط الشاشة لبدء التحدي' : 'TAP TO DUNK';
    const text = this.add.text(gameWidth * 0.5, 180, stringValTapToDunk, {
      // fontFamily: 'Arial',
      fontSize: 60, color: '#ffffff',
      stroke: '#48464F', strokeThickness: 4,
      align: 'center'
    }).setOrigin(0.5);
    text.setAlpha(0.3);
    if (this.game.options && this.game.options.canPlay) {
      text.setAlpha(0.8);
    }

    this.input.once('pointerdown', () => {
      this.scene.start('Game');
    });
  }

  getHoopPosition = () => {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.hoopHeight = gameHeight * 0.5;
    return {
      flipX: false,

      hoop1: {x: gameWidth - 200, y: this.hoopHeight + 71, width: 16, height: 10},
      hoop2: {x: gameWidth - 200, y: this.hoopHeight + 82, width: 16, height: 12},
      hoop3: {x: gameWidth - 127, y: this.hoopHeight + 68, width: 130, height: 16},
      hoop4: {x: gameWidth - 127, y: this.hoopHeight + 85, width: 130, height: 18},

      hoop5: {x: gameWidth - 54, y: this.hoopHeight + 71, width: 16, height: 10},
      hoop6: {x: gameWidth - 54, y: this.hoopHeight + 82, width: 16, height: 12},

      hoopb1: {x: gameWidth - 40, y: this.hoopHeight + 77, width: 12, height: 12},
      hoopb2: {x: gameWidth - 26, y: this.hoopHeight, width: 16, height: 240},
      hoopb3: {x: gameWidth - 9, y: this.hoopHeight + 77, width: 18, height: 28},
      hoopNet: {x: gameWidth - 127, y: this.hoopHeight + 120, width: 155, height: 76},
    }
  }
}
