import React, {unstable_Profiler as Profiler, useRef} from "react";
import {cloneDeep} from "lodash";
import {ButtonBase, makeStyles} from "@material-ui/core";
import ViewHelper from "./ViewHelper";

const useStyles = makeStyles(() => ({
  buttonOpacity: {
    // display: 'flex',
    // flexDirection: 'column',
    '&:hover': {
      opacity: 0.8,
      // transform: 'scale(1.01)',
    },
    '&.Mui-disabled': {
      opacity: 0.3,
    },
    '&:focus': {
      opacity: 0.5,
      // transform: 'scale(.95)'
    },
  },
  buttonOpacityDisabled: {
    // display: 'flex',
    // flexDirection: 'column',
    opacity: 0.3,
    // '&:hover': {
    //   opacity: 0.3,
    //   transform: 'scale(1.01)',
    // },
    // '&.Mui-disabled': {
    //   opacity: 0.3,
    // },
    // '&:focus': {
    //   opacity: 0.5,
    //   transform: 'scale(.95)'
    // },
  },
}));

const TouchableOpacity = React.memo((props) => {
  const propsOnClick = props.onClick;

  const classes = useStyles();

  const refButton = useRef();

  const onClick = (event) => {
    propsOnClick(event);
    if (refButton) {
      refButton.current.blur();
    }
  };

  // const propsExcept = cloneDeep(props);
  // delete propsExcept.classes;
  // delete propsExcept.onClick;

  return (
    // <div {...props} className={props.disabled ? classes.buttonOpacityDisabled : classes.buttonOpacity} onClick={props.disabled ? null : props.onClick}>{props.children}</div>
    <ButtonBase disableRipple ref={refButton} {...props} onClick={onClick} className={classes.buttonOpacity}>{{...props}.children}</ButtonBase>
  );
})

export default TouchableOpacity;
