import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import app from './appReducer';
import loadingReducer from './loadingReducer';
import blockedLoadingReducer from './blockedLoadingReducer';
import login from './loginReducer';
import registration from './registrationReducer';
import admin from './adminReducer';
import signUp from './registerReducer';
import redeemOffer from './redeemOfferReducer';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  app,
  isLoading: loadingReducer,
  isBlockedLoading: blockedLoadingReducer,
  login,
  redeemOffer,
  signUp,
  registration,
  admin
});

export default rootReducer;
