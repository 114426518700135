import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import auth from '../utils/auth';
import {deleteToken} from '../utils/pushNotification';
import {unregister} from '../utils/serviceWorker';

import {doLogout} from '../actions/loginActions';
import Loader from "./commons/Loader";
import {PathJoin, PathLogin, PathRegister} from "src/js/components/routes/NavHelper";

class Logout extends React.Component {

  componentDidMount() {
    if (auth.isAuthenticated()) {
      this.props.doLogout();
      this.onLogout(500);
        // .then(() => {
        //   this.onLogout();
        // }).error(() => {
        //   this.onLogout();
        // });
    } else {
      this.onLogout();
    }
  }

  onLogout(delay = 0) {
    unregister();
    deleteToken();
    auth.deleteUserToken();
    setTimeout(() => {
      if (this.props.history.location.search && this.props.history.location.search.length > 0) {
        this.props.history.replace({
          pathname: PathJoin,
          search: this.props.history.location.search
        });
      } else {
        this.props.history.replace(PathLogin);
      }
    }, delay);
  }

  render() {
    return <Loader />;
  }
}

Logout.propTypes = {
  history: PropTypes.object,
  doLogout: PropTypes.func,
}

function mapStateToProps(state) {
  const props = {
    classes: state.classes,
    app: state.app,
    router: state.router,
    isLoading: state.isLoading,
  };
  if (state.router && state.router.location && state.router.location.state) {
    // props.fromLocation = state.router.location.state.fromLocation;
  }
  return props;
}

const mapDispatchToProps = (dispatch) => {
  return {
    doLogout: () => dispatch(doLogout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
