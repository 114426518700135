import moment from "moment";
import {FormattedMessage} from "react-intl";
import React from "react";

// moment.updateLocale(currentLocale, {
//   months : [
//     locString('january'),
//     locString('february'),
//     locString('march'),
//     locString('april'),
//     locString('may'),
//     locString('june'),
//     locString('july'),
//     locString('august'),
//     locString('september'),
//     locString('october'),
//     locString('november'),
//     locString('december')
//   ],
//   weekdays : [
//     locString('sunday'),
//     locString('monday'),
//     locString('tuesday'),
//     locString('wednesday'),
//     locString('thursday'),
//     locString('friday'),
//     locString('saturday'),
//   ],
//   meridiem : function (hour, minute, isLowercase) {
//     if (hour < 12) {
//       return locString('am');
//     } else {
//       return locString('pm');
//     }
//   }
// });

export default class DateTimeHelper {

  static getDisplayDate(dateString) {
    // return moment(dateString).format('D MMMM YYYY, h:mm A');
    return moment(dateString).format('DD/MM/YYYY, h:mm A');
  }

  static getDisplayDateWOYear(dateString) {
    return moment(dateString).format('DD/MM/YYYY, h:mm A')
  }

  static getDisplayDateInvoice(dateString) {
    return moment(dateString).format('DD/MM/YYYY hh:mm A')
  }

  static getDisplayDateOnly(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  static getDisplayTimeOnly(dateString) {
    return moment(dateString).format('hh:mm A')
  }

  static getDateFromDateString(dateString) {
    return moment(dateString).toDate();
  }

  static getApiDateOnlyStringFromDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  static getDateMonthOnlyStringFromDate(date) {
    return moment(date).format('DD-MM');
  }

  static getInputDateString(dateString) {
    return moment(dateString).format('DD/MM/YYYY');
  }

  static getInputTimeString(dateString) {
    return moment(dateString).format('hh:mm A');
  }

  static getLogTimeString(dateString) {
    return moment(dateString).format('HH:mm:ss');
  }

  static getInputDateTimeString(dateString) {
    return moment(dateString).format('DD/MM/YYYY hh:mm A');
  }

  static getDateAfterYears(date, years) {
    let momentNow = moment(date);
    momentNow = momentNow.add(years, "years");
    return momentNow.toDate();
  }

  static getDateAfterYearsFromNow(years) {
    let momentNow = moment();
    momentNow = momentNow.add(years, "years");
    return momentNow.toDate();
  }

  static getDateBeforeYearsFromNow(years) {
    let momentNow = moment();
    momentNow = momentNow.subtract(years, "years");
    return momentNow.toDate();
  }

  static getDateAfterMonths(date, months) {
    let momentNow = moment(date);
    momentNow = momentNow.add(months, "months");
    return momentNow.toDate();
  }

  static getDateAfterDays(date, days) {
    let momentNow = moment(date);
    momentNow = momentNow.add(days, "days");
    return momentNow.toDate();
  }

  static getDateAfterMinutesFromNow(minutes) {
    let momentNow = moment();
    momentNow = momentNow.add(minutes, "minutes");
    return momentNow.toDate();
  }

  static getDateBeforeMinutesFromNow(minutes) {
    let momentNow = moment();
    momentNow = momentNow.subtract(minutes, "minutes");
    return momentNow.toDate();
  }

  static getDateAfterHoursFromNow(hours) {
    let momentNow = moment();
    momentNow = momentNow.add(hours, "hours");
    return momentNow.toDate();
  }

  static getDateBeforeYears(date, years) {
    let momentNow = moment(date);
    momentNow = momentNow.subtract(years, "years");
    return momentNow.toDate();
  }

  static getDateBeforeYearsFromNow(years) {
    let momentNow = moment();
    momentNow = momentNow.subtract(years, "years");
    return momentNow.toDate();
  }

  static isDateInBetween(date, date1, date2) {
    const dateToCheck = moment(date);
    return dateToCheck.isValid() && dateToCheck.isSameOrAfter(date1) && dateToCheck.isSameOrBefore(date2);
  }

  static isDateAfter(date, date1) {
    const dateToCheck = moment(date);
    return dateToCheck.isValid() && dateToCheck.isSameOrAfter(date1);
  }

  static getSecondsDiffFromNow(dateString) {
    return (moment(dateString).toDate().getTime() - new Date().getTime()) / 1000;
  }

  static getDisplayTimeForSeconds(seconds, duration) {
    let durationFormat = 'mm:ss';
    if (duration >= 60) {
      durationFormat = 'mm:ss';
    } else if (duration >= 60 * 60) {
      durationFormat = 'HH:mm:ss';
    }
    return moment.utc(seconds * 1000).format(durationFormat);
  }

  static getTimeSinceWithAgo(dateString) {
    if (!dateString) {
      return null;
    }
    let date = moment(dateString).toDate();

    if (typeof date !== 'object') {
      date = new Date(date);
    }

    let seconds = Math.max(Math.floor((new Date() - date) / 1000), 0);
    let timeSince = null;

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      timeSince = <FormattedMessage id={interval === 1 ? 'generic.agoYearWAgo' : 'generic.agoYearsWAgo'} values={{'count': interval}} />;
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        timeSince = <FormattedMessage id={interval === 1 ? 'generic.agoMonthWAgo': 'generic.agoMonthsWAgo'} values={{'count': interval}} />
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          timeSince = <FormattedMessage id={interval === 1 ? 'generic.agoDayWAgo' : 'generic.agoDaysWAgo'} values={{'count': interval}} />;
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            timeSince = <FormattedMessage id={interval === 1 ? 'generic.agoHourWAgo' : 'generic.agoHoursWAgo'} values={{'count': interval}} />;
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              timeSince = <FormattedMessage id={interval === 1 ? 'generic.agoMinuteWAgo' : 'generic.agoMinutesWAgo'} values={{'count': interval}} />;
            } else {
              timeSince = <FormattedMessage id={interval === 1 ? 'generic.agoSecondWAgo' : 'generic.agoSecondsWAgo'} values={{'count': seconds}} />;
            }
          }
        }
      }
    }
    return timeSince;
  }

  static getValidity(dateString, labelIdPrefix = 'generic.ago') {
    const endTime = moment(dateString).toDate().getTime();
    const nowTime = new Date().getTime();
    const diff = endTime - nowTime;
    if (diff > 0) {
      const seconds = Math.floor(diff / 1000);
      let timeSince = null;

      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Year' : labelIdPrefix+ 'Years'} values={{'count': interval}} />;
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Month': labelIdPrefix+ 'Months'} values={{'count': interval}} />;
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Day' : labelIdPrefix+ 'Days'} values={{'count': interval}} />;
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Hour' : labelIdPrefix+ 'Hours'} values={{'count': interval}} />;
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Minute' : labelIdPrefix+ 'Minutes'} values={{'count': interval}} />;
              } else {
                timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Second' : labelIdPrefix+ 'Seconds'} values={{'count': seconds}} />;
              }
            }
          }
        }
      }
      return timeSince;
    } else {
      return <FormattedMessage id='generic.ended' />;
    }
  }

  static getSince(dateString, labelIdPrefix = 'generic.ago') {
    const startTime = moment(dateString).toDate().getTime();
    const nowTime = new Date().getTime();
    const diff = nowTime - startTime;
    if (diff > 0) {
      const seconds = Math.floor(diff / 1000);
      let timeSince = null;

      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Year' : labelIdPrefix+ 'Years'} values={{'count': interval}} />;
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Month': labelIdPrefix+ 'Months'} values={{'count': interval}} />;
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Day' : labelIdPrefix+ 'Days'} values={{'count': interval}} />;
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Hour' : labelIdPrefix+ 'Hours'} values={{'count': interval}} />;
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Minute' : labelIdPrefix+ 'Minutes'} values={{'count': interval}} />;
              } else {
                timeSince = <FormattedMessage id={interval === 1 ? labelIdPrefix+ 'Second' : labelIdPrefix+ 'Seconds'} values={{'count': seconds}} />;
              }
            }
          }
        }
      }
      return timeSince;
    } else {
      return <FormattedMessage id='generic.ended' />;
    }
  }

  static getValidityInTime(dateString) {
    const endTime = moment(dateString).toDate().getTime();
    const nowTime = new Date().getTime();
    const diff = endTime - nowTime;
    if (diff > 0) {
      const seconds = Math.floor(diff / 1000);
      let timeSince = null;

      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        timeSince = <FormattedMessage id={interval === 1 ? 'time.inYear' : 'time.inYears'} values={{'count': interval}} />;
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          timeSince = <FormattedMessage id={interval === 1 ? 'time.inMonth': 'time.inMonths'} values={{'count': interval}} />;
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            timeSince = <FormattedMessage id={interval === 1 ? 'time.inDay' : 'time.inDays'} values={{'count': interval}} />;
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              timeSince = <FormattedMessage id={interval === 1 ? 'time.inHour' : 'time.inHours'} values={{'count': interval}} />;
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                timeSince = <FormattedMessage id={interval === 1 ? 'time.inMinute' : 'time.inMinutes'} values={{'count': interval}} />;
              } else {
                timeSince = <FormattedMessage id={interval === 1 ? 'time.inSecond' : 'time.inSeconds'} values={{'count': seconds}} />;
              }
            }
          }
        }
      }
      return timeSince;
    }
    return null;
  }
}
