import {cloneDeep, concat, isEmpty, isPlainObject, map} from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';
import constants from '../constants';
import CampaignHelper from "../utils/CampaignHelper";
import {FormattedMessage} from "react-intl";
import React from "react";

export default function funReducer(state = cloneDeep(initialState.admin), action) {

  switch (action.type) {

    case types.RESET_FUN_ACTIVITIES_ERROR: {
      state.funActivities.errMsg = null;
      state.funActivities.successMsg = null;
      return state;
    }

    case types.FETCH_CACHE_FUN_ACTIVITIES: {
      if (state.cacheListData && state.cacheListData.data && state.cacheListData.type === action.type) {
        state.funActivities.paginated = state.cacheListData.data;
        state.funActivities.scrollTo = state.cacheListData.scrollPosition;
        state.funActivities.isFetchInProgress = false;
        state.funActivities.cache = true;
      } else {
        state.funActivities.cacheMiss = true;
      }
      return state;
    }

    case types.FETCH_FUN_ACTIVITIES: {
      delete state.activities.cache;
      delete state.activities.cacheMiss;
      state.funActivities.isFetchInProgress = true;
      state.funActivities.errMsg = '';
      state.funActivities.errMsgF = '';
      return state;
    }
    case types.FETCH_FUN_ACTIVITIES_SUCCESS: {
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        data.data.forEach((item) => {
          if (item.isVoucher) {
            item.type = constants.activity.types.VOUCHER;
          }
        });

        if (data.page === 1) {
          state.funActivities.paginated = data;
        } else {
          state.funActivities.paginated.data = concat(state.funActivities.paginated.data, data.data);
        }
        state.funActivities.paginated.page = data.page;
        state.funActivities.paginated.perPage = data.perPage;
        state.funActivities.paginated.canLoadMore = data.canLoadMore;
        state.funActivities.paginated.totalSize = calculateTotalActivitySize(data);
      }
      state.funActivities.isFetchInProgress = false;
      return state;
    }
    case types.FETCH_FUN_ACTIVITIES_FAILURE: {
      if (action.data && action.data.error) {
        state.funActivities.errMsgF = action.data.error;
      } else {
        state.funActivities.errMsgF = 'generic.server_error_msg';
      }
      state.funActivities.isFetchInProgress = false;
      return state;
    }

    case types.SHOW_FUN_ACTIVITY_LINK_COPIED_SNACKBAR: {
      state.funActivities.errMsg = null;
      state.funActivities.successMsg = <FormattedMessage id={'campaign.linkCopied'}/>;
      return state;
    }
    case types.FETCH_FUN_ACTIVITY: {
      state.funActivities.errMsg = null;
      state.funActivities.errCode = null;
      return state;
    }
    case types.FETCH_FUN_ACTIVITY_SUCCESS:
    case types.FETCH_FUN_ACTIVITY_BLOCKED_LOADING_SUCCESS: {
      state.funActivities.activity = cloneDeep(initialState.admin.funActivities.activity);
      state.funActivities.activity.data = action.data;
      state.funActivities.activity.data.withDetails = true;
      if (action.data.business) {
        state.funActivities.activity.data.businesses = [action.data.business];
      }
      if (action.data.branches) {
        state.funActivities.activity.data.branchIds = map(action.data.branches, (branch) => branch.id);
      }
      if (!action.data.qOptions) {
        state.funActivities.activity.data.qOptions = [];
      }
      if (!action.data.cQuestions) {
        state.funActivities.activity.data.cQuestions = [];
      }
      if (!action.data.consumableWDays) {
        state.funActivities.activity.data.consumableWDays = [];
      }
      if (action.data.rewardedOffer) {
        action.data.rewardedOffer.name = action.data.rewardedOffer.title; // CampaignHelper.getName(action.data.rewardedOffer);
        state.funActivities.activity.data.rewards = [action.data.rewardedOffer];
      } else {
        state.funActivities.activity.data.rewards = [];
      }
      if (action.data.offer) {
        action.data.offer.name = action.data.offer.title; // CampaignHelper.getName(action.data.offer);
        state.funActivities.activity.data.winRewards = [action.data.offer];
      } else {
        state.funActivities.activity.data.winRewards = [];
      }
      if (action.data.slotValues) {
        action.data.slotValues.forEach((slot) => {
          if (slot.offers) {
            slot.offers.forEach((offer) => {
              offer.name = offer.title; // CampaignHelper.getName(offer);
            });
          }
        });
      }
      if (action.data.cQuestions) {
        action.data.cQuestions.forEach((question) => {
          question.name = question.title; // CampaignHelper.getName(question);
          state.funActivities.questionOptions[question.id] = [];
        });
      }
      if (action.data.adsActivities) {
        action.data.adsActivities.forEach((activity) => {
          activity.name = CampaignHelper.getName(activity);
        });
        state.funActivities.activity.data.searchedAdsActivities = action.data.adsActivities;
      } else {
        state.funActivities.activity.data.searchedAdsActivities = [];
      }

      return state;
    }
    case types.FETCH_FUN_ACTIVITY_FAILURE:
    case types.FETCH_FUN_ACTIVITY_BLOCKED_LOADING_FAILURE: {
      state.funActivities.activity = cloneDeep(initialState.admin.funActivities.activity);
      if (action.data && action.data.error) {
        state.funActivities.activity.errMsg = action.data.error;
        state.funActivities.activity.errCode = action.data.errorCode;
      } else {
        state.funActivities.activity.errMsg = 'generic.server_error_msg';
      }
      // state.funActivities.activity = newActivity;
      return state;
    }

    case types.MAKE_ACTION_IN_ACTIVITY:
    case types.MAKE_ACTION_IN_ACTIVITY_SUCCESS:
    case types.MAKE_ACTION_IN_ACTIVITY_FAILURE:
    case types.MAKE_ACTION_IN_ACTIVITY_BLOCKING:
    case types.MAKE_ACTION_IN_ACTIVITY_BLOCKING_SUCCESS:
    case types.MAKE_ACTION_IN_ACTIVITY_BLOCKING_FAILURE:
    case types.GET_ACTIVITY_REVIEWS:
    case types.GET_ACTIVITY_REVIEWS_SUCCESS:
    case types.GET_ACTIVITY_REVIEWS_FAILURE: {
      return state;
    }

    case types.FETCH_FUN_WINNERS: {
      state.activityWinners.errMsg = '';
      state.activityWinners.errMsgF = '';
      state.activityWinners.isLoading = true;
      return state;
    }
    case types.FETCH_FUN_WINNERS_SUCCESS: {
      state.activityWinners.isLoading = false;
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        state.activityWinners.paginated = data;
        state.activityWinners.paginated.totalSize = calculateTotalActivitySize(data);
      }
      return state;
    }
    case types.FETCH_FUN_WINNERS_FAILURE: {
      state.activityWinners.isLoading = false;
      if (action.data && action.data.error) {
        state.activityWinners.errMsg = action.data.error;
      } else {
        state.activityWinners.errMsgF = 'generic.server_error_msg';
      }
      return state;
    }
    case types.FETCH_FUN_DUMMY_WINNERS: {
      state.activityWinners.errMsg = '';
      state.activityWinners.errMsgF = '';
      state.activityWinners.isLoading = false;
      state.activityWinners.paginated.data = [];
      state.activityWinners.paginated.totalSize = 0;
      return state;
    }

    case types.FETCH_FUN_ANSWER_DISTRIBUTIONS: {
      state.activityAnswerDistributions.errMsg = '';
      state.activityAnswerDistributions.errMsgF = '';
      state.activityAnswerDistributions.isLoading = true;
      return state;
    }
    case types.FETCH_FUN_ANSWER_DISTRIBUTIONS_FAILURE: {
      state.activityAnswerDistributions.isLoading = false;
      if (action.data && action.data.error) {
        state.activityAnswerDistributions.errMsg = action.data.error;
      } else {
        state.activityAnswerDistributions.errMsgF = 'generic.server_error_msg';
      }
      return state;
    }
    case types.FETCH_FUN_ANSWER_DISTRIBUTIONS_SUCCESS: {
      state.activityAnswerDistributions.isLoading = false;
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        state.activityAnswerDistributions.totalAnswers = data.totalAnswers;
        state.activityAnswerDistributions.answers = data.answers;
        state.activityAnswerDistributions.qTitle = data.qTitle;
      }
      return state;
    }
    case types.FETCH_DUMMY_FUN_ANSWER_DISTRIBUTIONS: {
      state.activityAnswerDistributions.errMsg = '';
      state.activityAnswerDistributions.errMsgF = '';
      state.activityAnswerDistributions.isLoading = false;
      state.activityAnswerDistributions.totalAnswers = action.data.totalAnswers;
      state.activityAnswerDistributions.answers = action.data.answers;
      return state;
    }

    default:
      return state;
  }

}

export const calculateTotalActivitySize = (data) => {
  const count = data.data.length;
  if (data.page === 1 && count === 0) return 0;

  if (!data.canLoadMore) {
    return ((data.page - 1) * data.perPage) + count;
  }

  return ((data.page - 1) * data.perPage) + count + 1;
};
