export const ColorClear = 'rgba(0,0,0,0)';
export const ColorWhite = '#FFFFFF';
export const ColorBlack = '#000000';
export const ColorGreen = '#4caf50';
export const ColorOrange = '#F57C00';
export const ColorOrangeDark = '#F3705A';
export const ColorNegative = '#EA4335';

// export const ColorTitle = 'rgba(0,0,0,0.9)';
// export const ColorSubTitle = 'rgba(0,0,0,0.65)';
// export const ColorOtherTitle = '#848688';
// export const ColorTitleDisabled = 'rgba(0,0,0,0.2)';

export const ColorNav = '#272D2C';
export const ColorStatusDialog = '#7F7F7F';
export const ColorTitle = '#272D2C';
export const ColorTitleLite = '#474D4C';
export const ColorSubTitle = '#676D7C';
export const ColorOtherTitle = '#979D9C';
export const ColorTitleDisabled = 'rgba(0,0,0,0.35)';

export const ColorTheme = 'rgb(5,96,253)';
export const ColorThemeLite = 'rgba(5,96,253,0.3)';
export const ColorSecondary = '#777D7C';
export const ColorAccent = '#EC407A';
export const ColorAccentLite = 'rgba(236,64,122,0.5)';
export const ColorAccentHover = '#D81B60';

// export const ColorCard = '#FAFCFE';
export const ColorCard = '#FFFFFF';
export const ColorCardXS = '#FFFFFF';
export const ColorActivityBackground = '#f6f6f6';
export const ColorActivityDetail = '#FAFAFA';
export const ColorListBackground = '#FDFFE6';
export const ColorPlaceholder = '#979b9f';
export const ColorStatusBar = '#FFC600';
export const ColorEarning = '#4CAF50';
export const ColorGold = '#d4af37';

// export const ColorBackground = '#E8EEFA';
export const ColorBackground = '#EFF5FF';
// export const ColorBackgroundXS = '#F2F8FF';
export const ColorBackgroundXS = '#EFF5FF';
export const ColorSeparator = '#E8EEFA';
export const ColorSeparatorLite = '#efefef';
export const ColorLink = '#007aff';
export const ColorBorder = 'rgba(0,0,0,0.03)';
export const ColorFieldBorder = '#CED5DE';
export const ColorFieldBorderHover = 'rgba(5,96,253,0.5)';
export const ColorFieldBorderSelected = 'rgba(5,96,253,1)';
export const ColorFieldHover = '#ffffff';
export const ColorFieldDisabled = '#f6f6f6';
export const ColorField = '#ffffff';
export const ColorFieldBgWhite = 'rgb(245,247,249)';
export const ColorActivityForm = '#F1F1F1';
export const ColorActivityFormImage = '#EAEAEA';
export const ColorForm = '#F3F3F3';
export const ColorHeaderBar = 'rgba(237,238,241)';
export const ColorHeaderBarBorder = 'rgb(227,229,236)';
export const ColorCardField = 'rgba(5,96,253,0.01)';
export const ColorCardFieldHover = 'rgba(5,96,253,0.03)';
export const ColorCardFieldSelected = 'rgba(5,96,253,0.05)';
export const ColorDividerDark = 'rgba(0,0,0,0.2)';
export const ColorDivider = 'rgba(255,255,255,0.2)';
export const ColorPreviewBorder = '#EAECEF';
export const ColorPreviewBorderHover = '#DADCDF';
export const ColorButtonSelected = '#E4EEFF';
export const ColorButtonSelectedHover = '#c3d3ee';
export const ColorOverlay = 'rgba(0,0,0,0.4)';
export const ColorStatusOverlay = '#8e919a';

export const ColorMediaPickerBg = '#080E1A';
export const ColorMediaPickerBgPreview = '#282E3A';
export const ColorMediaPickerBgCrop = '#484E5A';
export const ColorMediaPickerBgError = '#585E6A';
export const ColorTableBorder = 'rgba(0,0,0,0.2)';

export const ColorUIChecker1 = 'rgb(240, 92, 102)';
export const ColorUIChecker2 = 'rgb(127, 215, 69)';
export const ColorUIChecker3 = 'rgb(63, 150, 225)';

export const ColorActive = '#1DB8AF';
export const ColorInactive = '#EA4335';
